.page-blog-category, .page-blog-landing, .page-blog-article {
  .blog-header {
    background: white;
    border-bottom: none;
    padding: 40px 0 0px 0;
  }

  .blog-header .blog-category-name {
    margin: 0;
    line-height: 40px;
    color: transparent;

    &::before {
      content: "Blog";
      color: #555;
    }
  }

  .blog-header .blog-breadcrumbs > nav ul.breadcrumb {
    padding-left: 0 !important;

    li.breadcrumb-item a, .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item {
      padding: 0 5px;
      line-height: 23px;

      span {
        color: transparent;
      }

      span::before {
        content: "Blog";
        color: #7e93a9;
      }
    }
  }
}

