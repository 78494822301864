.page-checkout {
  h1.h2 {
    display: none !important;
  }

  .widget-separator {
    display: none !important;
  }

  .basket-item-container:first-child {
    @media only screen and (min-width: 768px) {
      padding-top: 60px;
    }
  }

  .widget.widget-text.widget-none.pt-2.pb-2.mb-0 {
    display: none;
  }

  .method-list-item[data-id="6000"] .content {
    color: transparent;

    &::before {
      content: "Vorkasse";
      color: #555;
    }
  }

}
