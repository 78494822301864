#page-body .registration.container-max {
  padding-top: 50px;
  padding-bottom: 50px;

  #nx-registration-container {
    padding: 0;
    border: 1px solid #ddd;

    h1 {
      border-bottom: 1px solid #ddd;
      padding: 0px 20px;
      margin: 0;
      font-size: 1.3rem;
      background: #fafafa;
    }

    form {
      padding: 10px 20px;

      div {
        input {
          padding: 1.5rem 1rem 0.5rem 1rem !important;
        }

        label {
          font-size: 0.8em;
          color: #555;
          padding-top: 6px;
        }
      }

      button {
        i {
          display: none !important;
        }
      }
    }

    // Accept AGB
    .col-12 .form-check {
      display: flex;
      align-items: center;
      margin-top: 0 !important;
    }

    span.hr-text {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      color: grey;
      background: white;
      padding: 10px;
    }
  }
}
