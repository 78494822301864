/* -----------------------------CATEGORY PAGE------------------------------ */

.categoriegrid.page-content {
  background-color: white;
  margin-top: -10px;
}

.categoriegrid .category-title {
  color: #555;
  width: 75%;
  padding-left: 7px;
  float: right;
  font-weight: 500;
  margin-bottom: 0;
  display: contents;
  font-size: 24.5pt;
}

.categoriegrid .list-controls {
  margin-bottom: 0 !important;
}

.categoriegrid .list-controls-inner {
  background-color: white;
  padding-left: 0!important;
}

.categoriegrid .custom-select {
  border: 1px solid #cccccc;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555555;
}

.categoriegrid .add-to-basket-lg-container {
  display: none;
}

.ww-container .cmp-product-thumb {
  border: 1px solid gray;
}

.categoriegrid .thumb-title {
  font: bold 14px "Open sans condensed", sans-serif;
  color: #495057;
  text-align: center;
  max-height: 38px;
  margin: 8px 0;
  padding: 0 10px;
  overflow: hidden;
}

.categoriegrid .thumb-title span {
  display: none;
}

.categoriegrid .thumb-content .thumb-meta {
  margin-top: 0;
}

.categoriegrid .prices {
  justify-content: center;
  line-height: 24px;
}

.categoriegrid .prices .price-view-port {
  -webkit-box-flex: 0;
  flex: 0;
  margin-right: 5px;
}

.categoriegrid .prices .crossprice {
  font-size: 13px;
  font-family: "Open sans condensed", sans-serif;
  color: red;
  white-space: nowrap;
}

.categoriegrid .prices .price {
  color: #495057;
  font: bold 15px "Open sans condensed", sans-serif;
  white-space: nowrap;
}

.categoriegrid .thumb-content {
  text-align: center;
}

.categoriegrid .thumb-content .vat {
  font-size: 10px;
  white-space: nowrap;
}

.paragraph1 {
  width: 59%;
  float: right;
}

.elements1 {
  width: 35%;
  text-align: center;
  float: left;
  border: 1px solid #ebedf2;
}

.elements1 img {
  width: 80%;
}

.uebersicht1 {
  width: 49%;
  float: right;
  border: 1px solid #ebedf2;
  text-align: center;
  margin-bottom: 10px;
}

.uebersicht2 {
  width: 49%;
  text-align: center;
  float: left;
  border: 1px solid #ebedf2;
  margin-bottom: 10px;
}

.uebersicht p {
  padding-top: 10px;
}

.back-to-top {
  right: 13rem !important;
}

.nx-item-list-item-wrapper .cmp-product-thumb .thumb-content {
  padding: 0.3rem;
}

/* MOBILE */
@media (min-width: 768px) {
  .sidebar-filters .collapse {
    display: block;
  }

  .categoriegrid .list-controls .selected-filters {
    float: left;
    padding-top: 0;
    width: 100%;
    margin-top: 0;
  }

  .ww-container {
    display: flex;
  }

  .ww-container #filterCollapse .container-max {
    padding: 0 15px 0 0;
    background-color: white;
  }

  .ww-container .product-list {
    width: inherit;
  }

  .categoriegrid .category-description {
    width: 75%;
    float: right;
  }
}

@media (max-width: 991.98px) {
.categoriegrid .list-sort,
  .categoriegrid .list-itemsperpage {
    display: none;
  }
}

@media (max-width: 767px) {
  .categoriegrid .category-title {
    width: 100%;
    font-size: 23.5pt;
  }

  .categoriegrid .cmp-paginator {
    float: left !important;
  }

  .categoriegrid .list-controls .filter-toggle {
    display: block;
  }

  .ww-container .sidebar-filters {
    margin-bottom: 20px;
  }

  .ww-container .sidebar-filters .show {
    display: none;
  }

  .ww-container .collapse:not(.show) {
    display: block;
  }

  .ww-container .sidebar-filters {
    min-width: 100%;
    max-width: 100%;
  }
}

#nx-category-breadcrumbs {
  ul {
    padding-left: 0 !important;
  }
}

.section .paragraph {
  width: 60%;
  float: left;
}

.section .paragraph-25 {
  width: 75%;
  float: left;
}

.section .elements {
  width: 35%;
  text-align: center;
  float: right;
  border: 1px solid #ebedf2;
}

.section .elements img {
  width: 80%;
}

.section .elements-25 {
  width: 21%;
  text-align: center;
  float: right;
  border: 1px solid #ebedf2;
}

.section .elements-25 img {
  width: 80%;
}

@media (max-width: 767px) {
  .section .paragraph {
    width: 100%;
  }

  .section .elements {
    width: 100%;
    float: none;
    margin-bottom: 25px;
  }
}

.second-infoBox {
  border: 1px solid #555;
  margin: 5px;
  background: rgba(235, 237, 242, 0.8);
}

.second-infoBox p {
  padding: 1em;
}

.paragraph1 {
  width: 62%;
  float: right;
}

.paragraph45 {
  width: 45%;
  float: left;
}

.elements1 {
  width: 35%;
  text-align: center;
  float: left;
  border: 1px solid #ebedf2;
}

.elements1 img {
  width: 80%;
}

@media (max-width: 767px) {
  .paragraph1 {
    width: 100%;
  }

  .elements1 {
    width: 100%;
    float: none;
    margin-bottom: 25px;
  }
}

.uebersicht1 {
  width: 49%;
  float: right;
  border: 1px solid #ebedf2;
  text-align: center;
  margin-bottom: 10px;
}

.elements55 {
  width: 55%;
  text-align: center;
  float: right;
  border: 1px solid #ebedf2;
}

.elements55 img {
  width: 80%;
}

@media (max-width: 767px) {
  .paragraph45 {
    width: 100%;
  }

  .elements55 {
    width: 100%;
    float: none;
    margin-bottom: 25px;
  }
}

.elements55 {
  width: 49%;
  float: right;
  border: 1px solid #ebedf2;
  text-align: center;
  margin-bottom: 10px;
}

.uebersicht2 {
  width: 49%;
  text-align: center;
  float: left;
  border: 1px solid #ebedf2;
  margin-bottom: 10px;
}

.uebersicht p {
  padding-top: 10px;
}

.uebersicht2:hover {
  background-color: #fa7d18;
  box-shadow: 1px 1px 3px #ebedf2;
  color: white;
}

.uebersicht1:hover {
  background-color: #fa7d18;
  box-shadow: 1px 1px 3px #ebedf2;
  color: white;
}

@media (max-width: 767px) {
  .uebersicht1 {
    width: 100%;
  }

  .uebersicht2 {
    width: 100%;
    float: none;
    margin-bottom: 25px;
  }
}

#nx-custom-sidebar div.list-controls .filter-collapse .page-content {
  margin: 0 15px!important;
}

.list-controls .filter-collapse .page-content .form-check {
  display: flex;
  align-items: center;
}

.list-controls .filter-collapse .page-content .h3 {
  font-size: 1.15rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.list-controls .filter-collapse .page-content .form-check input {
  margin-right: .5rem;
}

.list-controls .filter-collapse .page-content .form-check label {
  margin-bottom: 0;
  line-height: 24px;
  font-size: 13px;
}

.list-controls .filter-collapse .page-content .form-check .filter-badge {
  display: none;
}

@media (max-width: 991.98px) {
  .filter-wrapper .filter-toggle {
    position: relative;
    top: 0;
    right: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0%);
    left: 0;
    display: flex!important;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
    font-size: 1.25rem;
  }
    
  .list-controls .filter-collapse {
    margin: 0;
    padding: 0;
  }
    
  .list-controls .filter-collapse .page-content {
    padding: 1rem!important;
    margin: 0!important;
    width: 100%!important;
  }
 
}
