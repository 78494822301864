/* using breakpoints
@media (max-width: 599px)
@media (min-width: 600px)
@media (min-width: 900px)
@media (min-width: 1200px)
@media (min-width: 1800px) */

/* -----------------------------GENERAL------------------------------ */
.breadcrumbs nav .breadcrumb-item a .fa-home {
  width: 35px !important;
}

.breadcrumb {
  background-color: transparent !important;
}

body {
  background: white;
  font: 12px/24px "Open Sans", sans-serif;
  color: var(--nx-brand-grey) !important;

}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3 {
  margin: 12px 0;
  font-family: inherit;
  font-weight: 300;
  line-height: 24px;
  color: inherit;
  text-rendering: optimizelegibility;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #999;
}

/* critical rule */
h1,
h2,
h3,
.h1,
.h2,
.h3 {
  color: var(--nx-brand-grey);
}

/* critical rule */
h1,
.h1,
/* nx: added */
h2,
.h2 {
  /* nx: changed */
  font-size: 36px;
  /* font-size: 38.5px; */
  font-weight: 300;
  /* nx: merged */
  line-height: 48px;
}

/* nx: merged with h1 */
/* xxxcritical rule */
/*h1,
.h1 {
    line-height: 48px;
}*/
/* nx: merged with h1 */
/*h2,
.h2 {
    font-size: 31.5px;
}*/
/* critical rule */
h3,
.h3 {
  font-size: 24.5px;
}

/* nx: partially merged with h1*/
/*h2,*/
/*.h2,*/
/* critical rule */
h3,
.h3 {
  line-height: 36px;
}

h4,
.h4 {
  font-size: 17.5px;
}

h3.small,
.h3.small {
  font-size: 20px;
  line-height: 24px;
}

h5 {
  font-size: 14px;
  font-weight: 700;
}

h6 {
  font-size: 11.9px;
  font-weight: 700;
}

h1 small {
  font-size: 24.5px;
}

h2 small {
  font-size: 17.5px;
}

h3 small {
  font-size: 14px;
}

h4 small {
  font-size: 14px;
}

p,
.p,
ul.ulSpace>li:last-child {
  margin: 0 0 12px;
}

cite {
  font-style: normal;
}

.muted {
  color: #999;
}

#page-body {
  margin-top: 25px;
}

#page-body,
.wrapper-main {
  background: #fff;
  overflow: hidden;
}

div.page-content {
  background: #fbfbfb;
}

#page-body>.parallax-img-container {
  display: none;
}

.bg-white {
  border-radius: 0.3rem;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.fa {
  margin-right: 3px;
}

.fa.fa-heart-o,
.fa.fa-paper-plane-o {
  font-family: FontAwesome;
}

@media (max-width: 899px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .container-max {
    width: 750px;
  }

  .page-home .widget-inner.col-md-6 {
    min-width: 100%;
    flex: 0 0 100%;
  }

  .hidden-sm {
    display: none;
  }
}

/* -----------------------------HEADER------------------------------ */



.wrapper-main header#page-header {
  background: white;
}

/*
@media only screen and (min-width: 900px) {
    .wrapper-main header#page-header {
        min-height: 169px;
    }
}

@media only screen and (max-width: 899px) {
    .wrapper-main header#page-header {
        min-height: 116px;
    }
}
*/

.top-bar {
  padding-bottom: 2px;
}

.top-bar .controls-list>li a:not(.btn) {
  padding: 10px 13px 9px;
}

.top-bar .controls-list>li a>span {
  font: bold 14px/20px "Open Sans Condensed", sans-serif;
}

.top-bar .controls-list .control-basket .toggle-basket-preview:hover {
  background-color: var(--nx-brand-primary);
  height: 45px;
}

.top-bar .controls-list .control-basket .toggle-basket-preview {
  background-color: #4d4f4d;
  color: #fff;
  font-size: 1.2em;
}

.login,
.registration {
  padding-top: 80px;
  padding-bottom: 80px;
}

.net-xpress-logo {
  max-width: 225px;
  margin: 5px auto;
}

.logo-container {
  text-align: center;
}

.mein-konto-ul {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.8;
}

/* -----------------------------STARTSEITE OWL CAROUSEL------------------------------ */

.item {
  height: 480px;
}

.owlcar-bg-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.owlcar-info {
  width: 50%;
  float: right;
  margin-top: 60px !important;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 70px;
  transform: translate3d(80px, 0px, 0px);
}

.owl-item.active .owlcar-info {
  transition: all 3000ms ease 0s;
  transform: translate3d(0px, 0px, 0px);
}

.owlcar-textholder {
  margin: 10px 12px 12px;
}

.owl-carousel h1 {
  margin-bottom: 12px;
  line-height: 1.2;
  font-size: 36px;
  color: var(--nx-brand-grey);
}

.owl-carousel strong {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 121%;
}

.owlcar-desc {
  margin-top: 17px;
}

.owlcar-img-thumbs {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.owl-carousel .item .owlcar-img-thumbs>img {
  width: auto;
  border: 1px solid #ddd;
  margin-left: 12px;
}

.owlcar-img-thumbs.trusted {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 12px;
}

.owl-carousel .item .owlcar-img-thumbs.trusted>img {
  border: none;
}

.owlcar-trusted-desc {
  font-size: 11px;
  line-height: 12px;
  padding: 15px;
}

.owlcar-trusted-rtg {
  width: 50%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 400px;
  transform: translate3d(80px, 0px, 0px);
}

.owl-item.active .owlcar-trusted-rtg {
  transition: all 3000ms ease 0s;
  transform: translate3d(0px, 0px, 0px);
}

.trusted-stars {
  font-size: 55px;
  line-height: 1;
  color: #edd27f;
}

.trusted-rating-ratio {
  font-weight: 700;
  line-height: 1.73;
  font-size: 26px;
  font-family: "Open Sans Condensed", sans-serif;
}

.trusted-rating {
  font-weight: 700;
  font-size: 45px;
  line-height: 1;
  font-family: "Open Sans Condensed", sans-serif;
  margin-bottom: 50px;
}

@media (min-width: 900px) and (max-width: 1199px) {

  .item,
  .owlcar-trusted-rtg {
    height: 360px;
  }

  .owlcar-desc {
    font-size: 13px;
    margin-top: 1em;
  }

  .owl-carousel h1 {
    font-size: 24px;
  }

  .owl-carousel .item .owlcar-img-thumbs>img {
    height: 80px;
  }

  .owlcar-info {
    margin-top: 35px;
  }

  .trusted-rating {
    margin-bottom: 150px;
    font-size: 173%;
  }

  .trusted-stars {
    font-size: 211%;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .hidden-md-down {
    display: none !important;
  }

  .item,
  .owlcar-trusted-rtg {
    height: 280px;
  }

  .owl-carousel h1 {
    font-size: 24px;
  }

  .owl-carousel strong {
    font-size: 12px;
  }

  .owlcar-info {
    margin-top: 0;
  }

  .owlcar-desc {
    font-size: 12px;
    margin-top: 1em;
  }

  .owlcar-img-thumbs,
  .owlcar-img-thumbs.trusted {
    display: none;
  }

  .trusted-stars {
    font-size: 211%;
  }

  .trusted-rating {
    font-size: 173%;
    margin-bottom: 130px;
  }
}

@media (max-width: 599px) {
  .hidden-md-down {
    display: none !important;
  }

  .item,
  .owlcar-trusted-rtg {
    height: 160px;
  }

  .owlcar-info {
    margin-top: 0;
  }

  .owl-carousel h1 {
    font-size: 13px;
    margin-top: 50px;
    padding-right: 12px;
  }

  .owl-carousel strong {
    display: none;
  }

  .owlcar-desc {
    display: none;
  }

  .owlcar-img-thumbs,
  .owlcar-img-thumbs.trusted {
    display: none;
  }

  .trusted-stars {
    font-size: 211%;
  }

  .trusted-rating {
    font-size: 173%;
  }

  .trusted-rating-ratio {
    display: none;
  }
}

/* -----------------------------STARTSEITE NETX-OPENER------------------------------ */

.netx-opener h1 {
  font-size: 36px;
  margin: 10px 0;
  line-height: 48px;
  color: var(--nx-brand-grey)5;
}

.netx-opener p {
  font-size: 12px;
  margin-bottom: 12px;
}

.netx-opener h3 {
  font: 700 16px/25px "Open sans condensed", sans-serif;
  color: var(--nx-brand-grey);
}

.netx-opener img {
  position: absolute;
  bottom: 4em;
}

/* -----------------------------STARTSEITE------------------------------ */

.widget-primary .bg-appearance {
  background-color: white;
  color: var(--nx-brand-grey);
}

.widget-item-list-caption p {
  font-size: 36px;
  text-transform: none;
}

.widget-grid {
  padding-bottom: 12px;
}

.widget-primary .bg-appearance {
  background-color: #ffffff !important;
  color: var(--nx-brand-grey) !important;
}

.fw-normal {
  font-weight: normal;
}

.content-nav>li {
  list-style: outside none none;
  margin-bottom: 6px;
}

.content-nav>li>.btn {
  display: inline-block;
  width: 100%;
  margin-bottom: 6px;
  text-align: left;
}

.mlmr-auto {
  margin: 0 auto;
}

.content-nav>li {
  list-style: outside none none;
  margin-bottom: 6px;
}

.content-nav>li>.btn {
  display: inline-block;
  width: 100%;
  margin-bottom: 6px;
  text-align: left;
}

.mlmr-auto {
  margin: 0 auto;
}

.glossar-list:before,
.glossar-list:after,
.glossar-nav:before,
.glossar-nav:after {
  content: "";
  display: table;
}

.glossar-list:after,
.glossar-nav:after {
  clear: both;
}

.glossar-list,
.glossar-nav {
  font-size: 14px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 0;
  list-style: none;
}

.glossar-list>dt,
.glossar-list>dd,
.glossar-nav>li {
  margin-bottom: 1em;
  padding-left: 15px;
  padding-right: 15px;
}

.glossar-list>dt {
  text-transform: uppercase;
  clear: both;
  line-height: 2px;
  padding-top: 9px;
  padding-bottom: 9px;
}

.glossar-list>dt::after {
  content: "";
  display: block;
  border-bottom: 1px solid #ddd;
  margin-left: 1.5em;
}

.glossar-list>dd {
  float: left;
  width: 100%;
}

.glossar-nav>li {
  float: left;
  width: 100%;
  text-align: left;
}

@media (min-width: 768px) {

  .glossar-list>dd,
  .glossar-nav>li {
    width: 33.33333333%;
  }

  .glossar-nav>li {
    text-align: center;
  }

  .glossar-nav>li:nth-of-type(1),
  .glossar-nav>li:nth-of-type(2) {
    text-align: left;
  }

  .glossar-nav>li:nth-of-type(4),
  .glossar-nav>li:nth-of-type(5) {
    text-align: right;
  }

  .glossar-nav>li:first-child,
  .glossar-nav>li:nth-of-type(5) {
    position: absolute;
    bottom: -1em;
  }

  .glossar-nav>li:nth-of-type(5) {
    right: 0;
  }

  .glossar-nav>li.glossar-nav-current {
    position: inherit;
  }
}

@media (min-width: 992px) {
  .glossar-list>dd {
    width: 25%;
  }
}

@media (min-width: 1200px) {

  .glossar-list>dd,
  .glossar-nav>li {
    width: 20%;
  }

  .glossar-nav>li:first-child,
  .glossar-nav>li:nth-of-type(5) {
    position: inherit;
  }
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  color: var(--nx-brand-grey);
}

// Trash icon
.fa-trash-o,
.fa-sign-out {
  font: normal normal normal 14px/1 FontAwesome !important;
}

.special-tags {
  display: none;
}

.owl-carousel .owl-dots {
  display: none !important;
}

#___ratingbadge_0 {
  z-index: 1000 !important;
}

#trustbadge-container-98e3dadd90eb493088abdc5597a70810 {
  z-index: 1005 !important;
}

.back-to-top {
  display: none !important;
}

// Buttons
.btn.btn-default {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 500 !important;
  cursor: pointer;
}

.upload-file-notifier {
  margin-top: -5px !important;
  width: 1172px !important;
  margin-left: -1px;
}

.basket-item-text {
  font-weight: 700 !important;
  padding-left: 0 !important;
  font-family: Open Sans, sans-serif !important;
}

.font-p70 {
  font-size: 75% !important;
  padding-top: 4px !important;
}

.font-p90 {
  font-size: 90%;
  padding-top: 4px !important;
}

.totals .cmp-totals {
  font-size: 75% !important;
  padding-top: 4px !important;
}

@media (min-width: 992px) {
  .basket-preview-wrapper {
    width: 40rem !important;
  }
}

.icon-space {
  padding-left: 2px;
}

.font-p21 {
  font-size: 21.5px !important;
  margin-bottom: 15px !important;
}

.sticky-header-overlap .widget-inner {
  z-index: 1;
}

.basket-preview-footer .btn-outline-primary {
  color: var(--nx-brand-primary) !important;
}

.basket-preview-footer .btn-outline-primary:hover {
  color: #ebedf2 !important;
}

.btn {
  border-radius: 0px !important;
}

.claim>span {
  background: transparent !important;
}

.requestBtn {
  margin-top: 7px;
  color: var(--nx-brand-primary) !important;
  font: 14px/20px Open Sans Condensed, sans-serif;
  font-weight: 700;
  height: 43px;
  padding: 11px 13px 9px;
  display: block;
  position: relative;
}

.requestBtn:hover {
  color: #ebedf2 !important;
}

.priceGrid {
  padding-bottom: 0px;
}

.add-to-basket-container .quantity-input-container .qty-box .qty-input {
  width: 90px;
}

.add-to-basket-container button {
  max-width: 100% !important;
  padding: 10px 87px !important;
}

.text-justify {
  text-align: justify;
}

.cross_box {
  display: flex;
  align-items: center;
  padding: 10px;
}

.cross_box .img {
  padding-left: 0;
}

.cross_box .prices {
  font-size: 12px;
  padding-right: 0;
  padding-top: 5px;

}

.cross_box .cross_price {
  color: var(--nx-brand-primary);
}

.cross_box .cross_btn,
.cross_btn {
  border: 1px solid red;
}

.btn-detail {
  font-size: 10px;
}

.cross_box .cross_btns .d-lg-none {
  display: block !important;
  font-size: 8px;
}

.cross_box .cross_btns .btn {
  font-size: 8px;
}

.showmore_cross , .showless_cross {
  display: block;
  cursor: pointer;
  text-align: right;
  top: -15px;
  position: relative;
  color: var(--nx-brand-primary) !important;
}



.item_availability {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  border: 1px solid #dee2e6;
}

.item_availability .icons {
  padding-right: 10px;
  width: 35px;
}

.item_availability .icons i {
  font-size: 20px;
}

.item_availability .txt {
  width: 100%;
  margin-top: -10px;
}

.item_availability .txt p.green {
  color: green;
  font-weight: 500;
  margin-bottom: -7px;
  font-size: 1rem;
}

.item_availability .txt p.grey {
  color: #909090;
  font-weight: 500;
  margin-bottom: -7px;
  font-size: 14px;
}

.item_availability .txt p {
  font-size: 10px;
  margin-bottom: -9px;
  line-height: 23px;
}

.addtobasket {
  display: none;
}

.cross_sell .cross-selling .addtobasket {
  display: block;
  font-size: 9px;
  padding: 6px 4px;
}

.prices .unit-price {
  font-size: 17px;
  font-weight: 800;
}

.icon-color {
  color: green;
}

.icon-mt5 {
  margin-top: -5px;
}

.price-mt25 {
  margin-top: 25px !important;
}

#vue-app {
  overflow-x: hidden;
}

.bg-white {
  color: var(--nx-brand-grey);
}
