/* Category Page - Filter Styles */
#nx-custom-sidebar {
  .filter-badge.bg-appearance {
    display: none;
  }

  .filter-wrapper > a {
    display: none;
  }

  .list-itemsperpage {
    display: none;
  }

  .list-sort {
    margin-left: -9px;
    padding: 0;
    width: 200px;
  }

  .filter-wrapper .list-controls-inner {
    padding: 0 !important;
  }

  .filter-wrapper > .filter-collapse {
    display: block;
    position: relative;
    z-index: 50;
  }

  div.list-controls .filter-collapse .page-content {
    padding: 0 0 0px 0 !important;
    border: none;
    margin-top: 20px;
  }

  div.list-controls .filter-collapse .page-content .h3 {
    font-weight: 500;
    font-size: 1.15rem;
    line-height: 1.2;
    padding: 0.3rem 0 0.3rem 0rem;
    margin-top: 1.2rem;
    margin-bottom: 0px;
    color: #495057;
  }

  /* Hide close btn for filter overlay */
  div.list-controls .filter-collapse .page-content .text-right {
    display: none;
  }

  .reset-filters {
    border-bottom: 1px solid lightgray;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .reset-filters button {
    margin-bottom: 20px;
  }

  div.list-controls .filter-collapse .page-content .form-check {
    padding: 0 3px;
    margin-bottom: 0;
    color: #495057;
    cursor: pointer;
    border: 1px solid transparent;

    label {
      margin-bottom: 0;
      margin-left: 4px;
    }

    &:hover {
      border: 1px solid #fa7d19;
    }
  }

  #nx-filter-title, .nx-filter-title h3 {
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.2;
    padding: 0.3rem 0 0.3rem 0rem;
    margin-top: 1.2rem;
    margin-bottom: 1rem;
    color: #555;
  }

}

@media (min-width: 992px) {
  #nx-custom-sidebar {  
    .filter-wrapper > a {
      display: none;
    }
  }
}