.page-singleitem {
  #page-body {
    margin-top: -0px;

    .payment-icons-text {
      p {
        font-size: 13px;
      }
    }

    .title-outer h1 {
      margin-top: 0px;
    }
  }

  nav {
    ul.breadcrumb {
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

div#page-body .single {
  background: white;
}

// Description
.ww-item-description p {
  font-size: 12px;
  line-height: 1.8;
  padding-top: 7px;
}

// Item Name
.single div.title-outer h1,
.page-singleitem .title-outer h1 span {
  color: #495057;
  font-size: 1.7rem;
  line-height: 40px;
  font-weight: 500 !important;
}

// Breadcrumbs
.breadcrumbs nav {
  background: white;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 100% !important;

  .breadcrumb-item a {
    color: #495057;

    .fa-home {
      width: 30px;
    }

    .fa-home:before {
      content: "Home";
      font-family: "Open Sans", sans-serif;
      font-weight: normal;
      color: #495057;
    }
  }
}

// Cross-Price
.ww-price {
  .crossprice del {
    color: red !important;
    font-size: 105%;
  }

  .base-price {
    display: none;
  }
}

// Graduated Prices
.ww-graduated-prices {
  margin-bottom: 5px;

  table div.grad-price-container {
    p.grad-quantity {
      font-size: 13px;
    }

    p.grad-price {
      font-size: 11px;
    }
  }
}

// Article Number
.articlenumber {
  font-size: 12px !important;
}

// Price
.price span {
  font-weight: 500 !important;
  color: #495057 !important;
}

// Ratings (Top of page)
.widget-feedback-average .ww-avg-rating-container {
  display: flex;

  .small-stars .feedback-star {
    height: 20px;
    width: 16px;
    line-height: 22px;
    font-size: 14px;
  }

  a.ww-avg-rating-text {
    color: #212529;
    font-size: 12px;

    i {
      color: #e86a05;
    }
  }
}

// Ratings (bottom of page)
.feedback-container {
  .feedback-stars-average,
  .feedback-average-count {
    display: none;
  }
}

// Angebot anfordern modal
#productRequest {
  .modal-title {
    font-weight: normal;
    font-family: "Open Sans", sans-serif;
  }

  .modal-body,
  #productRequest .form-control {
    font-size: 12px;
  }

  .lightgrey {
    background-color: lightgrey;
  }
}

a.angebotButton {
  background-color: #f8f8f8;
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.2);
  color: #444;
  font-size: 12px;
}

.qty-input {
  outline: none;
  height: 100%;
  min-height: 27px;
  width: 48px;
  padding: 0;
  -webkit-transition: all 0.2s cubic-bezier(0, 0, 0.26, 1);
  transition: all 0.2s cubic-bezier(0, 0, 0.26, 1);
  border: 1px solid #dcdbd8;
  border-radius: 0.1rem 0 0 0.1rem;
}

.qty-btn-container {
  width: 25px;
  position: relative;
  background: #f4f4f4;
  border: 1px solid #dcdbd8;
  border-left: none;
  border-radius: 0 0.1rem 0.1rem 0;
  overflow: hidden;
  height: 100%;
}

.qty-btn {
  width: 100%;
  height: calc(50% - 0.5px);
  min-height: 12px;
  text-align: center;
  -webkit-transition: all 0.2s cubic-bezier(0, 0, 0.26, 1);
  transition: all 0.2s cubic-bezier(0, 0, 0.26, 1);
  border-radius: 0.1rem;
  cursor: pointer;
  position: relative;
}

.qty-sign {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.qty-btn-seperator {
  height: 1px;
  background: #dcdbd8;
}
