@charset "UTF-8";
/* using breakpoints
@media (max-width: 599px)
@media (min-width: 600px)
@media (min-width: 900px)
@media (min-width: 1200px)
@media (min-width: 1800px) */
/* -----------------------------GENERAL------------------------------ */
.breadcrumbs nav .breadcrumb-item a .fa-home {
  width: 35px !important;
}

.breadcrumb {
  background-color: transparent !important;
}

body {
  background: white;
  font: 12px/24px "Open Sans", sans-serif;
  color: var(--nx-brand-grey) !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3 {
  margin: 12px 0;
  font-family: inherit;
  font-weight: 300;
  line-height: 24px;
  color: inherit;
  text-rendering: optimizelegibility;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #999;
}

/* critical rule */
h1,
h2,
h3,
.h1,
.h2,
.h3 {
  color: var(--nx-brand-grey);
}

/* critical rule */
h1,
.h1,
h2,
.h2 {
  /* nx: changed */
  font-size: 36px;
  /* font-size: 38.5px; */
  font-weight: 300;
  /* nx: merged */
  line-height: 48px;
}

/* nx: merged with h1 */
/* xxxcritical rule */
/*h1,
.h1 {
    line-height: 48px;
}*/
/* nx: merged with h1 */
/*h2,
.h2 {
    font-size: 31.5px;
}*/
/* critical rule */
h3,
.h3 {
  font-size: 24.5px;
}

/* nx: partially merged with h1*/
/*h2,*/
/*.h2,*/
/* critical rule */
h3,
.h3 {
  line-height: 36px;
}

h4,
.h4 {
  font-size: 17.5px;
}

h3.small,
.h3.small {
  font-size: 20px;
  line-height: 24px;
}

h5 {
  font-size: 14px;
  font-weight: 700;
}

h6 {
  font-size: 11.9px;
  font-weight: 700;
}

h1 small {
  font-size: 24.5px;
}

h2 small {
  font-size: 17.5px;
}

h3 small {
  font-size: 14px;
}

h4 small {
  font-size: 14px;
}

p,
.p,
ul.ulSpace > li:last-child {
  margin: 0 0 12px;
}

cite {
  font-style: normal;
}

.muted {
  color: #999;
}

#page-body {
  margin-top: 25px;
}

#page-body,
.wrapper-main {
  background: #fff;
  overflow: hidden;
}

div.page-content {
  background: #fbfbfb;
}

#page-body > .parallax-img-container {
  display: none;
}

.bg-white {
  border-radius: 0.3rem;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.fa {
  margin-right: 3px;
}

.fa.fa-heart-o,
.fa.fa-paper-plane-o {
  font-family: FontAwesome;
}

@media (max-width: 899px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  .container-max {
    width: 750px;
  }

  .page-home .widget-inner.col-md-6 {
    min-width: 100%;
    flex: 0 0 100%;
  }

  .hidden-sm {
    display: none;
  }
}
/* -----------------------------HEADER------------------------------ */
.wrapper-main header#page-header {
  background: white;
}

/*
@media only screen and (min-width: 900px) {
    .wrapper-main header#page-header {
        min-height: 169px;
    }
}

@media only screen and (max-width: 899px) {
    .wrapper-main header#page-header {
        min-height: 116px;
    }
}
*/
.top-bar {
  padding-bottom: 2px;
}

.top-bar .controls-list > li a:not(.btn) {
  padding: 10px 13px 9px;
}

.top-bar .controls-list > li a > span {
  font: bold 14px/20px "Open Sans Condensed", sans-serif;
}

.top-bar .controls-list .control-basket .toggle-basket-preview:hover {
  background-color: var(--nx-brand-primary);
  height: 45px;
}

.top-bar .controls-list .control-basket .toggle-basket-preview {
  background-color: #4d4f4d;
  color: #fff;
  font-size: 1.2em;
}

.login,
.registration {
  padding-top: 80px;
  padding-bottom: 80px;
}

.net-xpress-logo {
  max-width: 225px;
  margin: 5px auto;
}

.logo-container {
  text-align: center;
}

.mein-konto-ul {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.8;
}

/* -----------------------------STARTSEITE OWL CAROUSEL------------------------------ */
.item {
  height: 480px;
}

.owlcar-bg-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.owlcar-info {
  width: 50%;
  float: right;
  margin-top: 60px !important;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 70px;
  transform: translate3d(80px, 0px, 0px);
}

.owl-item.active .owlcar-info {
  transition: all 3000ms ease 0s;
  transform: translate3d(0px, 0px, 0px);
}

.owlcar-textholder {
  margin: 10px 12px 12px;
}

.owl-carousel h1 {
  margin-bottom: 12px;
  line-height: 1.2;
  font-size: 36px;
  color: var(--nx-brand-grey);
}

.owl-carousel strong {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 121%;
}

.owlcar-desc {
  margin-top: 17px;
}

.owlcar-img-thumbs {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.owl-carousel .item .owlcar-img-thumbs > img {
  width: auto;
  border: 1px solid #ddd;
  margin-left: 12px;
}

.owlcar-img-thumbs.trusted {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 12px;
}

.owl-carousel .item .owlcar-img-thumbs.trusted > img {
  border: none;
}

.owlcar-trusted-desc {
  font-size: 11px;
  line-height: 12px;
  padding: 15px;
}

.owlcar-trusted-rtg {
  width: 50%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 400px;
  transform: translate3d(80px, 0px, 0px);
}

.owl-item.active .owlcar-trusted-rtg {
  transition: all 3000ms ease 0s;
  transform: translate3d(0px, 0px, 0px);
}

.trusted-stars {
  font-size: 55px;
  line-height: 1;
  color: #edd27f;
}

.trusted-rating-ratio {
  font-weight: 700;
  line-height: 1.73;
  font-size: 26px;
  font-family: "Open Sans Condensed", sans-serif;
}

.trusted-rating {
  font-weight: 700;
  font-size: 45px;
  line-height: 1;
  font-family: "Open Sans Condensed", sans-serif;
  margin-bottom: 50px;
}

@media (min-width: 900px) and (max-width: 1199px) {
  .item,
.owlcar-trusted-rtg {
    height: 360px;
  }

  .owlcar-desc {
    font-size: 13px;
    margin-top: 1em;
  }

  .owl-carousel h1 {
    font-size: 24px;
  }

  .owl-carousel .item .owlcar-img-thumbs > img {
    height: 80px;
  }

  .owlcar-info {
    margin-top: 35px;
  }

  .trusted-rating {
    margin-bottom: 150px;
    font-size: 173%;
  }

  .trusted-stars {
    font-size: 211%;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  .hidden-md-down {
    display: none !important;
  }

  .item,
.owlcar-trusted-rtg {
    height: 280px;
  }

  .owl-carousel h1 {
    font-size: 24px;
  }

  .owl-carousel strong {
    font-size: 12px;
  }

  .owlcar-info {
    margin-top: 0;
  }

  .owlcar-desc {
    font-size: 12px;
    margin-top: 1em;
  }

  .owlcar-img-thumbs,
.owlcar-img-thumbs.trusted {
    display: none;
  }

  .trusted-stars {
    font-size: 211%;
  }

  .trusted-rating {
    font-size: 173%;
    margin-bottom: 130px;
  }
}
@media (max-width: 599px) {
  .hidden-md-down {
    display: none !important;
  }

  .item,
.owlcar-trusted-rtg {
    height: 160px;
  }

  .owlcar-info {
    margin-top: 0;
  }

  .owl-carousel h1 {
    font-size: 13px;
    margin-top: 50px;
    padding-right: 12px;
  }

  .owl-carousel strong {
    display: none;
  }

  .owlcar-desc {
    display: none;
  }

  .owlcar-img-thumbs,
.owlcar-img-thumbs.trusted {
    display: none;
  }

  .trusted-stars {
    font-size: 211%;
  }

  .trusted-rating {
    font-size: 173%;
  }

  .trusted-rating-ratio {
    display: none;
  }
}
/* -----------------------------STARTSEITE NETX-OPENER------------------------------ */
.netx-opener h1 {
  font-size: 36px;
  margin: 10px 0;
  line-height: 48px;
  color: var(--nx-brand-grey) 5;
}

.netx-opener p {
  font-size: 12px;
  margin-bottom: 12px;
}

.netx-opener h3 {
  font: 700 16px/25px "Open sans condensed", sans-serif;
  color: var(--nx-brand-grey);
}

.netx-opener img {
  position: absolute;
  bottom: 4em;
}

/* -----------------------------STARTSEITE------------------------------ */
.widget-primary .bg-appearance {
  background-color: white;
  color: var(--nx-brand-grey);
}

.widget-item-list-caption p {
  font-size: 36px;
  text-transform: none;
}

.widget-grid {
  padding-bottom: 12px;
}

.widget-primary .bg-appearance {
  background-color: #ffffff !important;
  color: var(--nx-brand-grey) !important;
}

.fw-normal {
  font-weight: normal;
}

.content-nav > li {
  list-style: outside none none;
  margin-bottom: 6px;
}

.content-nav > li > .btn {
  display: inline-block;
  width: 100%;
  margin-bottom: 6px;
  text-align: left;
}

.mlmr-auto {
  margin: 0 auto;
}

.content-nav > li {
  list-style: outside none none;
  margin-bottom: 6px;
}

.content-nav > li > .btn {
  display: inline-block;
  width: 100%;
  margin-bottom: 6px;
  text-align: left;
}

.mlmr-auto {
  margin: 0 auto;
}

.glossar-list:before,
.glossar-list:after,
.glossar-nav:before,
.glossar-nav:after {
  content: "";
  display: table;
}

.glossar-list:after,
.glossar-nav:after {
  clear: both;
}

.glossar-list,
.glossar-nav {
  font-size: 14px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 0;
  list-style: none;
}

.glossar-list > dt,
.glossar-list > dd,
.glossar-nav > li {
  margin-bottom: 1em;
  padding-left: 15px;
  padding-right: 15px;
}

.glossar-list > dt {
  text-transform: uppercase;
  clear: both;
  line-height: 2px;
  padding-top: 9px;
  padding-bottom: 9px;
}

.glossar-list > dt::after {
  content: "";
  display: block;
  border-bottom: 1px solid #ddd;
  margin-left: 1.5em;
}

.glossar-list > dd {
  float: left;
  width: 100%;
}

.glossar-nav > li {
  float: left;
  width: 100%;
  text-align: left;
}

@media (min-width: 768px) {
  .glossar-list > dd,
.glossar-nav > li {
    width: 33.33333333%;
  }

  .glossar-nav > li {
    text-align: center;
  }

  .glossar-nav > li:nth-of-type(1),
.glossar-nav > li:nth-of-type(2) {
    text-align: left;
  }

  .glossar-nav > li:nth-of-type(4),
.glossar-nav > li:nth-of-type(5) {
    text-align: right;
  }

  .glossar-nav > li:first-child,
.glossar-nav > li:nth-of-type(5) {
    position: absolute;
    bottom: -1em;
  }

  .glossar-nav > li:nth-of-type(5) {
    right: 0;
  }

  .glossar-nav > li.glossar-nav-current {
    position: inherit;
  }
}
@media (min-width: 992px) {
  .glossar-list > dd {
    width: 25%;
  }
}
@media (min-width: 1200px) {
  .glossar-list > dd,
.glossar-nav > li {
    width: 20%;
  }

  .glossar-nav > li:first-child,
.glossar-nav > li:nth-of-type(5) {
    position: inherit;
  }
}
h1,
h2,
h3,
.h1,
.h2,
.h3 {
  color: var(--nx-brand-grey);
}

.fa-trash-o,
.fa-sign-out {
  font: normal normal normal 14px/1 FontAwesome !important;
}

.special-tags {
  display: none;
}

.owl-carousel .owl-dots {
  display: none !important;
}

#___ratingbadge_0 {
  z-index: 1000 !important;
}

#trustbadge-container-98e3dadd90eb493088abdc5597a70810 {
  z-index: 1005 !important;
}

.back-to-top {
  display: none !important;
}

.btn.btn-default {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 500 !important;
  cursor: pointer;
}

.upload-file-notifier {
  margin-top: -5px !important;
  width: 1172px !important;
  margin-left: -1px;
}

.basket-item-text {
  font-weight: 700 !important;
  padding-left: 0 !important;
  font-family: Open Sans, sans-serif !important;
}

.font-p70 {
  font-size: 75% !important;
  padding-top: 4px !important;
}

.font-p90 {
  font-size: 90%;
  padding-top: 4px !important;
}

.totals .cmp-totals {
  font-size: 75% !important;
  padding-top: 4px !important;
}

@media (min-width: 992px) {
  .basket-preview-wrapper {
    width: 40rem !important;
  }
}
.icon-space {
  padding-left: 2px;
}

.font-p21 {
  font-size: 21.5px !important;
  margin-bottom: 15px !important;
}

.sticky-header-overlap .widget-inner {
  z-index: 1;
}

.basket-preview-footer .btn-outline-primary {
  color: var(--nx-brand-primary) !important;
}

.basket-preview-footer .btn-outline-primary:hover {
  color: #ebedf2 !important;
}

.btn {
  border-radius: 0px !important;
}

.claim > span {
  background: transparent !important;
}

.requestBtn {
  margin-top: 7px;
  color: var(--nx-brand-primary) !important;
  font: 14px/20px Open Sans Condensed, sans-serif;
  font-weight: 700;
  height: 43px;
  padding: 11px 13px 9px;
  display: block;
  position: relative;
}

.requestBtn:hover {
  color: #ebedf2 !important;
}

.priceGrid {
  padding-bottom: 0px;
}

.add-to-basket-container .quantity-input-container .qty-box .qty-input {
  width: 90px;
}

.add-to-basket-container button {
  max-width: 100% !important;
  padding: 10px 87px !important;
}

.text-justify {
  text-align: justify;
}

.cross_box {
  display: flex;
  align-items: center;
  padding: 10px;
}

.cross_box .img {
  padding-left: 0;
}

.cross_box .prices {
  font-size: 12px;
  padding-right: 0;
  padding-top: 5px;
}

.cross_box .cross_price {
  color: var(--nx-brand-primary);
}

.cross_box .cross_btn,
.cross_btn {
  border: 1px solid red;
}

.btn-detail {
  font-size: 10px;
}

.cross_box .cross_btns .d-lg-none {
  display: block !important;
  font-size: 8px;
}

.cross_box .cross_btns .btn {
  font-size: 8px;
}

.showmore_cross, .showless_cross {
  display: block;
  cursor: pointer;
  text-align: right;
  top: -15px;
  position: relative;
  color: var(--nx-brand-primary) !important;
}

.item_availability {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  border: 1px solid #dee2e6;
}

.item_availability .icons {
  padding-right: 10px;
  width: 35px;
}

.item_availability .icons i {
  font-size: 20px;
}

.item_availability .txt {
  width: 100%;
  margin-top: -10px;
}

.item_availability .txt p.green {
  color: green;
  font-weight: 500;
  margin-bottom: -7px;
  font-size: 1rem;
}

.item_availability .txt p.grey {
  color: #909090;
  font-weight: 500;
  margin-bottom: -7px;
  font-size: 14px;
}

.item_availability .txt p {
  font-size: 10px;
  margin-bottom: -9px;
  line-height: 23px;
}

.addtobasket {
  display: none;
}

.cross_sell .cross-selling .addtobasket {
  display: block;
  font-size: 9px;
  padding: 6px 4px;
}

.prices .unit-price {
  font-size: 17px;
  font-weight: 800;
}

.icon-color {
  color: green;
}

.icon-mt5 {
  margin-top: -5px;
}

.price-mt25 {
  margin-top: 25px !important;
}

#vue-app {
  overflow-x: hidden;
}

.bg-white {
  color: var(--nx-brand-grey);
}

:root {
  --nx-brand-primary: #FA7D19;
  --nx-brand-secondary: #fff;
  --nx-brand-grey: #495057;
}

/* ---------------------------- CE-CSS3 ----------------------------- */
.netxpress-megamenu {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.25);
}

#page-header .netxpress-megamenu .nav-content-wrapper #levelOneWrapper li.ww-level-one .layout-two-dropdown {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.25) !important;
}

.div.item-base-price.small.font-p70 {
  display: block;
}

.owl-carousel .owl-single-item-control {
  font-size: 2rem;
}

.right-topbar-section li.dropdown ul.dropdown-menu li {
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.right-topbar-section li.dropdown ul.dropdown-menu {
  padding: 10px 10px;
}

.right-topbar-section li.dropdown ul.dropdown-menu li:hover {
  border: 1px solid var(--nx-brand-primary);
}

.cross_sell .cross-selling .addtobasket {
  font-size: 9px;
}

span.nxtooltiptext.large-tooltip {
  display: none;
}

.nxrossseling-wi span.nxtooltiptext {
  display: none;
}

.cmp-method-list .method-list-item label,
.cmp-method-list .method-list-item .label {
  height: 100%;
}

.feedback-container .title {
  color: var(--nx-brand-grey);
}

.feedback-container {
  color: var(--nx-brand-grey);
}

sup {
  display: none;
}

.feedback-bar-fill.bg-primary.bg-appearance {
  background-color: var(--nx-brand-primary) !important;
}

.totalSum {
  font-size: 16px;
  font-weight: 900;
}

@media (min-width: 900px) {
  a.toggle-basket-preview {
    height: 45px !important;
  }

  p[data-v-86b5249e] {
    height: 80px !important;
  }

  .layout-two-dropdown {
    width: 1170px !important;
    margin-left: 15px;
  }

  .layout-one-dropdown {
    width: 1170px !important;
    margin-left: 15px;
  }
}
.qty-box {
  padding: 15px 0px 0px 0px;
}

.qty-box .qty-input {
  max-height: 49px;
  padding: 0px;
}

.qty-btn {
  max-height: 19px;
  float: left;
}

.basket-item-container-right {
  min-width: 150px;
}

.basket-item-container-right .qty-box-container {
  float: left;
}

.price-box .font-p90 {
  padding-top: 0px;
}

#addressMultiSelect16:after {
  content: "Adresse auswählen";
  color: var(--nx-brand-primary);
  cursor: pointer;
}

div[class^=addressMultiSelect]:after,
div[class*=" addressMultiSelect"]:after {
  color: red;
}

[class^=addressMultiSelect]:after [class$=addressMultiSelect]:after {
  color: red;
}

@media (max-width: 899px) {
  .netxpress-megamenu .nav-content-wrapper #levelOneWrapper li.ww-level-one {
    border-left: 1px solid #ddd;
    padding: 12px 1px 9px;
    height: 45px;
    float: left;
    margin-left: -1px;
  }

  .technical-data-list > dt[data-v-c0ad1828] {
    width: 36%;
  }
}
.sidebar-categories {
  display: none;
}

p.ww-category-title.mb-0 {
  display: none;
}

.item_availability .txt p.green {
  font-size: 1rem;
}

.cross_box .prices {
  margin-left: 12px;
}

a#nx-register-button {
  border-color: var(--nx-brand-primary) !important;
  background: #fff !important;
  color: var(--nx-brand-primary) !important;
}

a#nx-register-button:hover {
  border-color: var(--nx-brand-primary) !important;
  background: var(--nx-brand-primary) !important;
  color: #fff !important;
}

.breadcrumb {
  background-color: #fff !important;
}

.btn-nx-secondary {
  border-color: var(--nx-brand-primary) !important;
  background: #fff !important;
  color: var(--nx-brand-primary) !important;
}

.btn-nx-secondary:hover {
  border-color: var(--nx-brand-primary) !important;
  background: var(--nx-brand-primary) !important;
  color: #fff !important;
}

a.brand-logo.brand-logo-mobile img {
  position: relative;
  left: 40px;
  height: 40px;
}

.sticky-header-overlap .widget-inner {
  z-index: unset !important;
}

.single .variation-select .input-unit {
  margin-bottom: 0;
}

.single .single-carousel,
.page-singleitem .single-carousel {
  background: #fff;
  max-height: 500px;
}

img.owl-lazy {
  max-height: 480px;
}

div#thumb-carousel {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 15px;
}

@media (max-width: 900px) {
  .right-topbar-section li.dropdown a,
.right-topbar-section li.dropdown div.login-change,
.right-topbar-section li.list-inline-item a,
.right-topbar-section li.list-inline-item div.login-change {
    border: none !important;
  }

  .netxpress-megamenu .nav-content-wrapper .mobile-content .mobile-menu-open-btn {
    left: 19px !important;
  }

  .right-topbar-section li.isPhone.dropdown {
    position: relative;
  }

  .add-to-basket-container button {
    padding: 0px 0px !important;
  }

  .page-singleitem .title-outer h1 span,
.single div.title-outer h1 {
    color: var(--nx-brand-grey);
    font-size: 1.5rem;
    line-height: 31px;
  }
}
.registration.container-max.mt-3 .col-sm-10.offset-sm-1.col-md-6.offset-md-3 {
  border: 1px solid var(--nx-brand-grey);
}

.cmp-address-list .dropdown-toggle::after {
  content: "Adresse auswählen";
  color: var(--nx-brand-primary);
  margin: 8px;
  padding: 5px;
  position: absolute;
  top: 60%;
  padding-left: 15px;
  padding-right: 15px;
  right: 5px;
  border: none;
}

.row.wwcrosselling-itemname {
  height: 95px;
}

.qty-box {
  padding: 1px 0px 0px 0px;
}

img.owl-thumb.border-appearance {
  height: inherit !important;
  border: 1px solid #dee2e6;
  padding: 5px;
}

.owl-stage-outer.owl-height {
  height: inherit !important;
}

#thumb-carousel picture.active img.owl-thumb.border-appearance {
  border-color: var(--nx-brand-primary) !important;
}

div#thumb-carousel {
  padding-top: 10px;
}

div#thumb-carousel .owl-item.active {
  width: inherit !important;
}

#thumb-carousel > div.owl-stage-outer.owl-height .owl-item {
  width: inherit !important;
}

.nx-item-list-item.cmp-product-thumb {
  border: 1px solid #dee2e6;
}

.toggle-basket-preview {
  background: var(--nx-brand-primary) !important;
  height: inherit !important;
}

.search-submit {
  background-color: var(--nx-brand-primary) !important;
}

div#levelOneWrapper {
  margin-right: 3px;
}

.layout-one-dropdown {
  box-shadow: 0 1px 1px 1px #00000040 !important;
  -webkit-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.15);
}

div.invoice-addresses-select > div > div.cmp.cmp-address-list.mb-4 > div > div.dropdown-menu > ul > li {
  height: 100%;
}

div.shipping-addresses-select > div > div.cmp.cmp-address-list.mb-4 > div > div.dropdown-menu.show > ul > li {
  height: 100%;
}

p.nx-csitemname {
  height: 75px;
}

.basket-preview-wrapper > a {
  margin-top: 100px;
}

.basket-item-text {
  display: content;
}

/* div.item-base-price.small.font-p70 {display:none;} */
.basket-preview-content > a {
  display: content;
}

/* ADS */
#thumb-carousel > div.owl-stage-outer.owl-height > div {
  width: 100%;
  display: flex;
  align-items: center;
}

.prices.cross_price.nx-cs-crossprice {
  padding-top: -5px !important;
  margin-left: 25px !important;
}

@media (max-width: 899px) {
  .netxpress-megamenu .nav-content-wrapper #levelOneWrapper li.ww-level-one {
    border-left: none !important;
  }
}
.item {
  height: 100% !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 889px !important;
    margin: 1.75rem auto;
  }
}
.widget-provider-select.widget-primary input:checked + .provider-select-label {
  border: solid 1px var(--nx-brand-primary);
}

.nx-shipping-box .item_availability:hover {
  cursor: pointer;
}

#page-body > div > div > div > div > div:nth-child(1) > div.widget-inner.col-xl-5.widget-prop-xl-auto.col-lg-5.widget-prop-lg-auto.col-md-12.widget-prop-md-3-1.col-sm-12.widget-prop-sm-3-1.col-12.widget-prop-3-1 > div > div.widget.widget-sticky.sticky-element.is-sticky > div > div:nth-child(11) > div > div > div > div > div > div > div:nth-child(3) {
  display: none;
}

.owl-single-item-nav .fa-chevron-right,
.owl-single-item-nav .fa-chevron-left {
  -webkit-text-stroke: 2px white;
}

/*
.single-carousel.owl-carousel.owl-theme.owl-single-item.mt-0.owl-loaded.owl-drag {
    height: 350px;
}
div.single-carousel.owl-carousel.owl-theme.owl-single-item.mt-0.owl-loaded.owl-drag > div.owl-stage-outer.owl-height > div > div.owl-item.active > div > a > img{ height:300px;}
div.single-carousel.owl-carousel.owl-theme.owl-single-item.mt-0.owl-loaded.owl-drag > div.owl-stage-outer.owl-height > div > div.owl-item.active > div > a { height:300px;}
*/
/* Search Result Page Filter */
.nx-search-result-container #nx-filter-title {
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  padding: 0.3rem 0;
  margin-top: 1.2rem;
  margin-bottom: 0;
  color: var(--nx-brand-grey);
}

.nx-search-result-container .widget-filter-base .filter-toggle,
.nx-search-result-container .btn-primary,
.nx-search-result-container .form-check-wrapper .filter-badge {
  display: none;
}

.nx-search-result-container .filter-wrapper > .filter-collapse {
  display: block;
  position: relative;
  z-index: 50;
}

.nx-search-result-container .filter-collapse .page-content {
  padding: 0 !important;
  border: none;
  margin-top: 20px;
  margin-bottom: 1rem;
  background: #fff;
}

.nx-search-result-container .filter-collapse .page-content .h3 {
  font-weight: 500;
  font-size: 1.15rem;
  line-height: 1.2;
  padding: 0.3rem 0;
  margin-top: 1.2rem;
  margin-bottom: 1rem !important;
  color: #495057;
}

.nx-search-result-container .form-check-wrapper .form-check {
  padding: 0 3px;
  margin-bottom: 0;
}

.nx-search-result-container .form-check-wrapper input {
  vertical-align: middle;
}

.nx-search-result-container .search-results-categories .category-title {
  color: var(--nx-brand-grey);
  font-size: 17.5px;
  line-height: 36px;
  font-weight: 300;
  background: none;
  float: none;
  padding: 0;
}

.nx-search-result-container .search-results-categories ul {
  margin: 0;
  padding: 0;
}

.nx-search-result-container .search-results-categories ul > li > a {
  padding: 0.3rem 0 0.1rem !important;
}

.nx-search-result-container .search-results-categories .nav-item .expand-nav {
  padding: 0.3rem 0 0.1rem !important;
}

iframe[title=Trustami] {
  z-index: 1005 !important;
}

/* -----------------------------CATEGORY PAGE------------------------------ */
.categoriegrid.page-content {
  background-color: white;
  margin-top: -10px;
}

.categoriegrid .category-title {
  color: #555;
  width: 75%;
  padding-left: 7px;
  float: right;
  font-weight: 500;
  margin-bottom: 0;
  display: contents;
  font-size: 24.5pt;
}

.categoriegrid .list-controls {
  margin-bottom: 0 !important;
}

.categoriegrid .list-controls-inner {
  background-color: white;
  padding-left: 0 !important;
}

.categoriegrid .custom-select {
  border: 1px solid #cccccc;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555555;
}

.categoriegrid .add-to-basket-lg-container {
  display: none;
}

.ww-container .cmp-product-thumb {
  border: 1px solid gray;
}

.categoriegrid .thumb-title {
  font: bold 14px "Open sans condensed", sans-serif;
  color: #495057;
  text-align: center;
  max-height: 38px;
  margin: 8px 0;
  padding: 0 10px;
  overflow: hidden;
}

.categoriegrid .thumb-title span {
  display: none;
}

.categoriegrid .thumb-content .thumb-meta {
  margin-top: 0;
}

.categoriegrid .prices {
  justify-content: center;
  line-height: 24px;
}

.categoriegrid .prices .price-view-port {
  -webkit-box-flex: 0;
  flex: 0;
  margin-right: 5px;
}

.categoriegrid .prices .crossprice {
  font-size: 13px;
  font-family: "Open sans condensed", sans-serif;
  color: red;
  white-space: nowrap;
}

.categoriegrid .prices .price {
  color: #495057;
  font: bold 15px "Open sans condensed", sans-serif;
  white-space: nowrap;
}

.categoriegrid .thumb-content {
  text-align: center;
}

.categoriegrid .thumb-content .vat {
  font-size: 10px;
  white-space: nowrap;
}

.paragraph1 {
  width: 59%;
  float: right;
}

.elements1 {
  width: 35%;
  text-align: center;
  float: left;
  border: 1px solid #ebedf2;
}

.elements1 img {
  width: 80%;
}

.uebersicht1 {
  width: 49%;
  float: right;
  border: 1px solid #ebedf2;
  text-align: center;
  margin-bottom: 10px;
}

.uebersicht2 {
  width: 49%;
  text-align: center;
  float: left;
  border: 1px solid #ebedf2;
  margin-bottom: 10px;
}

.uebersicht p {
  padding-top: 10px;
}

.back-to-top {
  right: 13rem !important;
}

.nx-item-list-item-wrapper .cmp-product-thumb .thumb-content {
  padding: 0.3rem;
}

/* MOBILE */
@media (min-width: 768px) {
  .sidebar-filters .collapse {
    display: block;
  }

  .categoriegrid .list-controls .selected-filters {
    float: left;
    padding-top: 0;
    width: 100%;
    margin-top: 0;
  }

  .ww-container {
    display: flex;
  }

  .ww-container #filterCollapse .container-max {
    padding: 0 15px 0 0;
    background-color: white;
  }

  .ww-container .product-list {
    width: inherit;
  }

  .categoriegrid .category-description {
    width: 75%;
    float: right;
  }
}
@media (max-width: 991.98px) {
  .categoriegrid .list-sort,
.categoriegrid .list-itemsperpage {
    display: none;
  }
}
@media (max-width: 767px) {
  .categoriegrid .category-title {
    width: 100%;
    font-size: 23.5pt;
  }

  .categoriegrid .cmp-paginator {
    float: left !important;
  }

  .categoriegrid .list-controls .filter-toggle {
    display: block;
  }

  .ww-container .sidebar-filters {
    margin-bottom: 20px;
  }

  .ww-container .sidebar-filters .show {
    display: none;
  }

  .ww-container .collapse:not(.show) {
    display: block;
  }

  .ww-container .sidebar-filters {
    min-width: 100%;
    max-width: 100%;
  }
}
#nx-category-breadcrumbs ul {
  padding-left: 0 !important;
}

.section .paragraph {
  width: 60%;
  float: left;
}

.section .paragraph-25 {
  width: 75%;
  float: left;
}

.section .elements {
  width: 35%;
  text-align: center;
  float: right;
  border: 1px solid #ebedf2;
}

.section .elements img {
  width: 80%;
}

.section .elements-25 {
  width: 21%;
  text-align: center;
  float: right;
  border: 1px solid #ebedf2;
}

.section .elements-25 img {
  width: 80%;
}

@media (max-width: 767px) {
  .section .paragraph {
    width: 100%;
  }

  .section .elements {
    width: 100%;
    float: none;
    margin-bottom: 25px;
  }
}
.second-infoBox {
  border: 1px solid #555;
  margin: 5px;
  background: rgba(235, 237, 242, 0.8);
}

.second-infoBox p {
  padding: 1em;
}

.paragraph1 {
  width: 62%;
  float: right;
}

.paragraph45 {
  width: 45%;
  float: left;
}

.elements1 {
  width: 35%;
  text-align: center;
  float: left;
  border: 1px solid #ebedf2;
}

.elements1 img {
  width: 80%;
}

@media (max-width: 767px) {
  .paragraph1 {
    width: 100%;
  }

  .elements1 {
    width: 100%;
    float: none;
    margin-bottom: 25px;
  }
}
.uebersicht1 {
  width: 49%;
  float: right;
  border: 1px solid #ebedf2;
  text-align: center;
  margin-bottom: 10px;
}

.elements55 {
  width: 55%;
  text-align: center;
  float: right;
  border: 1px solid #ebedf2;
}

.elements55 img {
  width: 80%;
}

@media (max-width: 767px) {
  .paragraph45 {
    width: 100%;
  }

  .elements55 {
    width: 100%;
    float: none;
    margin-bottom: 25px;
  }
}
.elements55 {
  width: 49%;
  float: right;
  border: 1px solid #ebedf2;
  text-align: center;
  margin-bottom: 10px;
}

.uebersicht2 {
  width: 49%;
  text-align: center;
  float: left;
  border: 1px solid #ebedf2;
  margin-bottom: 10px;
}

.uebersicht p {
  padding-top: 10px;
}

.uebersicht2:hover {
  background-color: #fa7d18;
  box-shadow: 1px 1px 3px #ebedf2;
  color: white;
}

.uebersicht1:hover {
  background-color: #fa7d18;
  box-shadow: 1px 1px 3px #ebedf2;
  color: white;
}

@media (max-width: 767px) {
  .uebersicht1 {
    width: 100%;
  }

  .uebersicht2 {
    width: 100%;
    float: none;
    margin-bottom: 25px;
  }
}
#nx-custom-sidebar div.list-controls .filter-collapse .page-content {
  margin: 0 15px !important;
}

.list-controls .filter-collapse .page-content .form-check {
  display: flex;
  align-items: center;
}

.list-controls .filter-collapse .page-content .h3 {
  font-size: 1.15rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.list-controls .filter-collapse .page-content .form-check input {
  margin-right: 0.5rem;
}

.list-controls .filter-collapse .page-content .form-check label {
  margin-bottom: 0;
  line-height: 24px;
  font-size: 13px;
}

.list-controls .filter-collapse .page-content .form-check .filter-badge {
  display: none;
}

@media (max-width: 991.98px) {
  .filter-wrapper .filter-toggle {
    position: relative;
    top: 0;
    right: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0%);
    left: 0;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
    font-size: 1.25rem;
  }

  .list-controls .filter-collapse {
    margin: 0;
    padding: 0;
  }

  .list-controls .filter-collapse .page-content {
    padding: 1rem !important;
    margin: 0 !important;
    width: 100% !important;
  }
}
.page-singleitem #page-body {
  margin-top: 0px;
}
.page-singleitem #page-body .payment-icons-text p {
  font-size: 13px;
}
.page-singleitem #page-body .title-outer h1 {
  margin-top: 0px;
}
.page-singleitem nav ul.breadcrumb {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

div#page-body .single {
  background: white;
}

.ww-item-description p {
  font-size: 12px;
  line-height: 1.8;
  padding-top: 7px;
}

.single div.title-outer h1,
.page-singleitem .title-outer h1 span {
  color: #495057;
  font-size: 1.7rem;
  line-height: 40px;
  font-weight: 500 !important;
}

.breadcrumbs nav {
  background: white;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 100% !important;
}
.breadcrumbs nav .breadcrumb-item a {
  color: #495057;
}
.breadcrumbs nav .breadcrumb-item a .fa-home {
  width: 30px;
}
.breadcrumbs nav .breadcrumb-item a .fa-home:before {
  content: "Home";
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  color: #495057;
}

.ww-price .crossprice del {
  color: red !important;
  font-size: 105%;
}
.ww-price .base-price {
  display: none;
}

.ww-graduated-prices {
  margin-bottom: 5px;
}
.ww-graduated-prices table div.grad-price-container p.grad-quantity {
  font-size: 13px;
}
.ww-graduated-prices table div.grad-price-container p.grad-price {
  font-size: 11px;
}

.articlenumber {
  font-size: 12px !important;
}

.price span {
  font-weight: 500 !important;
  color: #495057 !important;
}

.widget-feedback-average .ww-avg-rating-container {
  display: flex;
}
.widget-feedback-average .ww-avg-rating-container .small-stars .feedback-star {
  height: 20px;
  width: 16px;
  line-height: 22px;
  font-size: 14px;
}
.widget-feedback-average .ww-avg-rating-container a.ww-avg-rating-text {
  color: #212529;
  font-size: 12px;
}
.widget-feedback-average .ww-avg-rating-container a.ww-avg-rating-text i {
  color: #e86a05;
}

.feedback-container .feedback-stars-average,
.feedback-container .feedback-average-count {
  display: none;
}

#productRequest .modal-title {
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
}
#productRequest .modal-body,
#productRequest #productRequest .form-control {
  font-size: 12px;
}
#productRequest .lightgrey {
  background-color: lightgrey;
}

a.angebotButton {
  background-color: #f8f8f8;
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.2);
  color: #444;
  font-size: 12px;
}

.qty-input {
  outline: none;
  height: 100%;
  min-height: 27px;
  width: 48px;
  padding: 0;
  -webkit-transition: all 0.2s cubic-bezier(0, 0, 0.26, 1);
  transition: all 0.2s cubic-bezier(0, 0, 0.26, 1);
  border: 1px solid #dcdbd8;
  border-radius: 0.1rem 0 0 0.1rem;
}

.qty-btn-container {
  width: 25px;
  position: relative;
  background: #f4f4f4;
  border: 1px solid #dcdbd8;
  border-left: none;
  border-radius: 0 0.1rem 0.1rem 0;
  overflow: hidden;
  height: 100%;
}

.qty-btn {
  width: 100%;
  height: calc(50% - 0.5px);
  min-height: 12px;
  text-align: center;
  -webkit-transition: all 0.2s cubic-bezier(0, 0, 0.26, 1);
  transition: all 0.2s cubic-bezier(0, 0, 0.26, 1);
  border-radius: 0.1rem;
  cursor: pointer;
  position: relative;
}

.qty-sign {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.qty-btn-seperator {
  height: 1px;
  background: #dcdbd8;
}

div.container-max.login {
  margin-top: 0;
  padding-top: 70px;
  padding-bottom: 70px;
}
div.container-max.login #nx-register-button {
  display: none !important;
}
div.container-max.login a.btn, div.container-max.login .login-container .btn {
  display: block;
}
div.container-max.login .panel-body {
  line-height: 18px;
  padding: 10px 20px 20px 20px;
  border: 1px solid #ddd;
  border-top: none;
}
div.container-max.login .panel-heading {
  display: block;
  line-height: 18px;
  border: 1px solid #ddd;
  padding: 3px 5px 3px 10px;
  color: #444;
  border-radius: 3px 3px 0 0;
  background-color: #fafafa;
}
div.container-max.login .panel-heading h3.panel-title {
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 0;
}

#page-body .registration.container-max {
  padding-top: 50px;
  padding-bottom: 50px;
}
#page-body .registration.container-max #nx-registration-container {
  padding: 0;
  border: 1px solid #ddd;
}
#page-body .registration.container-max #nx-registration-container h1 {
  border-bottom: 1px solid #ddd;
  padding: 0px 20px;
  margin: 0;
  font-size: 1.3rem;
  background: #fafafa;
}
#page-body .registration.container-max #nx-registration-container form {
  padding: 10px 20px;
}
#page-body .registration.container-max #nx-registration-container form div input {
  padding: 1.5rem 1rem 0.5rem 1rem !important;
}
#page-body .registration.container-max #nx-registration-container form div label {
  font-size: 0.8em;
  color: #555;
  padding-top: 6px;
}
#page-body .registration.container-max #nx-registration-container form button i {
  display: none !important;
}
#page-body .registration.container-max #nx-registration-container .col-12 .form-check {
  display: flex;
  align-items: center;
  margin-top: 0 !important;
}
#page-body .registration.container-max #nx-registration-container span.hr-text {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  color: grey;
  background: white;
  padding: 10px;
}

.page-checkout h1.h2 {
  display: none !important;
}
.page-checkout .widget-separator {
  display: none !important;
}
@media only screen and (min-width: 768px) {
  .page-checkout .basket-item-container:first-child {
    padding-top: 60px;
  }
}
.page-checkout .widget.widget-text.widget-none.pt-2.pb-2.mb-0 {
  display: none;
}
.page-checkout .method-list-item[data-id="6000"] .content {
  color: transparent;
}
.page-checkout .method-list-item[data-id="6000"] .content::before {
  content: "Vorkasse";
  color: #555;
}

.nx-search-result-container .widget-filter-base {
  padding-left: 0 !important;
}
.nx-search-result-container div.h3 {
  margin-bottom: 0 !important;
}
.nx-search-result-container .widget-toolbar .list-controls-inner .d-sm-flex {
  padding: 0 !important;
}
.nx-search-result-container .widget-toolbar .list-controls-inner .list-sort {
  padding: 0 !important;
}
.nx-search-result-container .search-results-categories .nav-link {
  border: 1px solid white !important;
}
.nx-search-result-container .search-results-categories .nav-link:hover {
  background: white !important;
  border: 1px solid #fa7d19 !important;
}

.page-blog-category .blog-header, .page-blog-landing .blog-header, .page-blog-article .blog-header {
  background: white;
  border-bottom: none;
  padding: 40px 0 0px 0;
}
.page-blog-category .blog-header .blog-category-name, .page-blog-landing .blog-header .blog-category-name, .page-blog-article .blog-header .blog-category-name {
  margin: 0;
  line-height: 40px;
  color: transparent;
}
.page-blog-category .blog-header .blog-category-name::before, .page-blog-landing .blog-header .blog-category-name::before, .page-blog-article .blog-header .blog-category-name::before {
  content: "Blog";
  color: #555;
}
.page-blog-category .blog-header .blog-breadcrumbs > nav ul.breadcrumb, .page-blog-landing .blog-header .blog-breadcrumbs > nav ul.breadcrumb, .page-blog-article .blog-header .blog-breadcrumbs > nav ul.breadcrumb {
  padding-left: 0 !important;
}
.page-blog-category .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item a, .page-blog-category .blog-header .blog-breadcrumbs > nav ul.breadcrumb .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item, .page-blog-landing .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item a, .page-blog-landing .blog-header .blog-breadcrumbs > nav ul.breadcrumb .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item, .page-blog-article .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item a, .page-blog-article .blog-header .blog-breadcrumbs > nav ul.breadcrumb .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item {
  padding: 0 5px;
  line-height: 23px;
}
.page-blog-category .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item a span, .page-blog-category .blog-header .blog-breadcrumbs > nav ul.breadcrumb .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item span, .page-blog-landing .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item a span, .page-blog-landing .blog-header .blog-breadcrumbs > nav ul.breadcrumb .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item span, .page-blog-article .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item a span, .page-blog-article .blog-header .blog-breadcrumbs > nav ul.breadcrumb .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item span {
  color: transparent;
}
.page-blog-category .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item a span::before, .page-blog-category .blog-header .blog-breadcrumbs > nav ul.breadcrumb .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item span::before, .page-blog-landing .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item a span::before, .page-blog-landing .blog-header .blog-breadcrumbs > nav ul.breadcrumb .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item span::before, .page-blog-article .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item a span::before, .page-blog-article .blog-header .blog-breadcrumbs > nav ul.breadcrumb .blog-header .blog-breadcrumbs > nav ul.breadcrumb li.breadcrumb-item span::before {
  content: "Blog";
  color: #7e93a9;
}

#startseite-carousel .owl-stage {
  transition: all 2000ms ease 0s !important;
}

.item {
  height: 480px;
}

.owl-theme .owl-nav {
  margin-top: 0px !important;
  text-align: unset !important;
}

.owl-theme .owl-nav [class*=owl-] {
  color: #495057;
  opacity: 0.5;
  font-size: 20px;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  width: 20px;
  height: 20px;
  background-color: white;
}
.owl-carousel .owl-nav button.owl-next:hover,
.owl-carousel .owl-nav button.owl-prev:hover {
  background: none !important;
}

.owl-carousel .owl-nav button.owl-next span,
.owl-carousel .owl-nav button.owl-prev span {
  font-size: 2em;
  font-weight: bold;
  position: absolute;
  top: 0;
  margin-top: -15px;
  left: 25%;
}
.owl-carousel .owl-nav button.owl-next span:hover,
.owl-carousel .owl-nav button.owl-prev span:hover {
  color: #495057;
}

.owlcar-bg-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  object-fit: cover !important;
}

.owlcar-info {
  width: 50%;
  float: right;
  margin-top: 103px;
  padding-left: 15px;
  padding-right: 15px;
}

.owlcar-textholder {
  margin: 10px 12px 12px;
  transform: translate3d(40%, 0px, 0px);
  opacity: 0;
}

.owl-item.active .owlcar-info .owlcar-textholder {
  transition: opacity 0s, transform 2000ms ease 0s !important;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}

.owl-carousel h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin-bottom: 12px;
  line-height: 1.2;
  font-size: 36px;
  color: #555;
}

.owl-carousel strong {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 121%;
}

.owlcar-desc {
  margin-top: 17px;
}

.owlcar-img-thumbs {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  transform: translate3d(20%, 0px, 0px);
  opacity: 0;
}

.owl-item.active .owlcar-info .owlcar-img-thumbs {
  transition: opacity 0s, transform 2000ms ease 0s !important;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .item .owlcar-img-thumbs > img {
  width: auto;
  border: 1px solid #ddd;
  margin-left: 12px;
}

.owlcar-img-thumbs.trusted {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 12px;
}

.owl-carousel .item .owlcar-img-thumbs.trusted > img {
  border: none;
}

.owlcar-trusted-desc {
  font-size: 11px;
  line-height: 12px;
  padding: 15px;
}

.owlcar-trusted-rtg {
  width: 50%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 400px;
  transform: translate3d(40%, 0px, 0px);
}

.owl-item.active .owlcar-trusted-rtg {
  transition: all 2000ms ease 0s !important;
  transform: translate3d(0px, 0px, 0px);
}

.trusted-stars {
  font-size: 55px;
  line-height: 1;
  color: #edd27f;
}

.trusted-rating-ratio {
  font-weight: 700;
  line-height: 1.73;
  font-size: 26px;
  font-family: "Open Sans Condensed", sans-serif;
}

.trusted-rating {
  font-weight: 700;
  font-size: 45px;
  line-height: 1;
  font-family: "Open Sans Condensed", sans-serif;
  margin-bottom: 50px;
}

@media (min-width: 900px) and (max-width: 1199px) {
  .item,
.owlcar-trusted-rtg {
    height: 360px;
  }

  .owlcar-desc {
    font-size: 13px;
    margin-top: 1em;
  }

  .owl-carousel h1 {
    font-size: 24px;
  }

  .owl-carousel .item .owlcar-img-thumbs > img {
    height: 80px;
  }

  .owlcar-info {
    margin-top: 35px;
  }

  .trusted-rating {
    margin-bottom: 150px;
    font-size: 173%;
  }

  .trusted-stars {
    font-size: 211%;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  .item,
.owlcar-trusted-rtg {
    height: 304px;
  }

  .owl-carousel h1 {
    font-size: 24px;
  }

  .owl-carousel strong {
    font-size: 12px;
  }

  .owlcar-info {
    margin-top: 0;
  }

  .owlcar-desc {
    font-size: 12px;
    margin-top: 1em;
  }

  .owlcar-img-thumbs,
.owlcar-img-thumbs.trusted {
    display: none;
  }

  .trusted-stars {
    font-size: 211%;
  }

  .trusted-rating {
    font-size: 173%;
    margin-bottom: 130px;
  }
}
@media (max-width: 599px) {
  .item,
.owlcar-trusted-rtg {
    height: 160px;
  }

  .owlcar-info {
    margin-top: 0;
  }

  .owl-carousel h1 {
    font-size: 13px;
    margin-top: 50px;
    padding-right: 12px;
  }

  .owl-carousel strong {
    display: none;
  }

  .owlcar-desc {
    display: none;
  }

  .owlcar-img-thumbs,
.owlcar-img-thumbs.trusted {
    display: none;
  }

  .trusted-stars {
    font-size: 211%;
  }

  .trusted-rating {
    font-size: 173%;
  }

  .trusted-rating-ratio {
    display: none;
  }
}
div.modal-content div.modal-header {
  background: #fafafa;
}
div.modal-content div.modal-header h4 {
  margin-top: 0;
}

form.login-container div input, .widget-address form div input {
  padding: 1.5rem 1rem 0.5rem 1rem !important;
}
form.login-container div label, .widget-address form div label {
  font-size: 0.8em;
  color: #555;
  padding-top: 6px;
}
form.login-container button i.fa-user, .widget-address form button i.fa-user {
  display: none !important;
}

/* Category Page - Filter Styles */
#nx-custom-sidebar {
  /* Hide close btn for filter overlay */
}
#nx-custom-sidebar .filter-badge.bg-appearance {
  display: none;
}
#nx-custom-sidebar .filter-wrapper > a {
  display: none;
}
#nx-custom-sidebar .list-itemsperpage {
  display: none;
}
#nx-custom-sidebar .list-sort {
  margin-left: -9px;
  padding: 0;
  width: 200px;
}
#nx-custom-sidebar .filter-wrapper .list-controls-inner {
  padding: 0 !important;
}
#nx-custom-sidebar .filter-wrapper > .filter-collapse {
  display: block;
  position: relative;
  z-index: 50;
}
#nx-custom-sidebar div.list-controls .filter-collapse .page-content {
  padding: 0 0 0px 0 !important;
  border: none;
  margin-top: 20px;
}
#nx-custom-sidebar div.list-controls .filter-collapse .page-content .h3 {
  font-weight: 500;
  font-size: 1.15rem;
  line-height: 1.2;
  padding: 0.3rem 0 0.3rem 0rem;
  margin-top: 1.2rem;
  margin-bottom: 0px;
  color: #495057;
}
#nx-custom-sidebar div.list-controls .filter-collapse .page-content .text-right {
  display: none;
}
#nx-custom-sidebar .reset-filters {
  border-bottom: 1px solid lightgray;
  margin-top: 20px;
  margin-bottom: 15px;
}
#nx-custom-sidebar .reset-filters button {
  margin-bottom: 20px;
}
#nx-custom-sidebar div.list-controls .filter-collapse .page-content .form-check {
  padding: 0 3px;
  margin-bottom: 0;
  color: #495057;
  cursor: pointer;
  border: 1px solid transparent;
}
#nx-custom-sidebar div.list-controls .filter-collapse .page-content .form-check label {
  margin-bottom: 0;
  margin-left: 4px;
}
#nx-custom-sidebar div.list-controls .filter-collapse .page-content .form-check:hover {
  border: 1px solid #fa7d19;
}
#nx-custom-sidebar #nx-filter-title, #nx-custom-sidebar .nx-filter-title h3 {
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  padding: 0.3rem 0 0.3rem 0rem;
  margin-top: 1.2rem;
  margin-bottom: 1rem;
  color: #555;
}

@media (min-width: 992px) {
  #nx-custom-sidebar .filter-wrapper > a {
    display: none;
  }
}
#nx-custom-sidebar,
.nx-search-result-container {
  /* Sidebar Category-List - Level 1 */
  /* Sidebar Category-List - Level 2 */
  /* Sidebar Category-List - Level 3 */
}
#nx-custom-sidebar .sidebar-categories > ul > li,
.nx-search-result-container .sidebar-categories > ul > li {
  display: none;
}
#nx-custom-sidebar .sidebar-categories > ul > li.active,
.nx-search-result-container .sidebar-categories > ul > li.active {
  display: block;
}
#nx-custom-sidebar .sidebar-categories > ul > li.active > a,
.nx-search-result-container .sidebar-categories > ul > li.active > a {
  background: white;
  font-weight: bold;
  font-size: 12px;
  font-weight: 500;
  color: #495057;
  padding: 0.3rem 0 0.1rem 0rem;
}
#nx-custom-sidebar .sidebar-categories > ul > li.active > ul,
.nx-search-result-container .sidebar-categories > ul > li.active > ul {
  display: block;
}
#nx-custom-sidebar .sidebar-categories > ul > li.active > ul,
.nx-search-result-container .sidebar-categories > ul > li.active > ul {
  display: block;
}
#nx-custom-sidebar .sidebar-categories > ul > li.active > ul > li,
.nx-search-result-container .sidebar-categories > ul > li.active > ul > li {
  display: block;
}
#nx-custom-sidebar .sidebar-categories > ul > li.active > ul > li.active > a,
.nx-search-result-container .sidebar-categories > ul > li.active > ul > li.active > a {
  border: 1px solid #fa7d19;
  color: #495057;
}
#nx-custom-sidebar .sidebar-categories > ul > li.active > ul > li > a,
.nx-search-result-container .sidebar-categories > ul > li.active > ul > li > a {
  background: white;
  padding: 0.015rem 0.4rem;
  margin: 0.03rem 0;
  border: 1px solid transparent;
  font-size: 12px;
  color: #495057;
}
#nx-custom-sidebar .sidebar-categories > ul > li.active > ul > li > a:hover,
.nx-search-result-container .sidebar-categories > ul > li.active > ul > li > a:hover {
  border: 1px solid #fa7d19;
}
#nx-custom-sidebar p.ww-category-title,
.nx-search-result-container p.ww-category-title {
  color: #555;
  font-size: 17.5px;
  line-height: 36px;
  font-weight: 300;
  margin: 0 0 8px 0;
}

/* Category Items */
.nx-item-list-item.cmp-product-thumb {
  border: 1px solid gray;
  border-radius: 0px;
  padding: 0;
}
.nx-item-list-item.cmp-product-thumb .thumb-inner .add-to-basket-lg-container {
  display: none;
}
.nx-item-list-item.cmp-product-thumb .technical-data-list {
  display: none;
}
.nx-item-list-item.cmp-product-thumb .graduated-prices {
  display: none;
}
.nx-item-list-item.cmp-product-thumb:hover {
  position: absolute;
  z-index: 900;
  max-width: 90%;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
}
.nx-item-list-item.cmp-product-thumb:hover .technical-data-list {
  display: block;
}
.nx-item-list-item.cmp-product-thumb:hover .graduated-prices {
  display: block;
}

span.custom-add-to-basket-container div * {
  min-width: 100%;
  min-height: 28px;
}
span.custom-add-to-basket-container button * {
  min-width: 100%;
  min-height: 28px;
}
span.custom-add-to-basket-container .d-inline.d-lg-none div {
  display: none;
}

.nx-cross-selling-horizontal .widget-caption,
.nx-cross-selling-vertical .widget-caption {
  padding: 0;
  margin-bottom: 0 !important;
}
.nx-cross-selling-horizontal .widget-caption h2,
.nx-cross-selling-vertical .widget-caption h2 {
  padding: 0;
}
.nx-cross-selling-horizontal .owl-carousel .owl-stage-outer,
.nx-cross-selling-vertical .owl-carousel .owl-stage-outer {
  overflow-x: hidden;
}
.nx-cross-selling-horizontal .category-unit-price,
.nx-cross-selling-vertical .category-unit-price {
  display: none;
}

.nx-cross-selling-horizontal .thumb-title {
  font: bold 14px "Open sans condensed", sans-serif;
  color: #495057;
  text-align: center;
  max-height: 38px;
  margin: 8px 0;
  padding: 0 10px;
  overflow: hidden;
}
.nx-cross-selling-horizontal .thumb-title span {
  display: none;
}
.nx-cross-selling-horizontal .thumb-content .thumb-meta {
  margin-top: 0;
}
.nx-cross-selling-horizontal .prices {
  justify-content: center;
  line-height: 24px;
}
.nx-cross-selling-horizontal .prices .price-view-port {
  -webkit-box-flex: 0;
  flex: 0;
  margin-right: 5px;
}
.nx-cross-selling-horizontal .prices .crossprice {
  font-size: 13px;
  font-family: "Open sans condensed", sans-serif;
  color: red;
  white-space: nowrap;
}
.nx-cross-selling-horizontal .prices .price {
  color: #495057;
  font: bold 15px "Open sans condensed", sans-serif;
  white-space: nowrap;
}
.nx-cross-selling-horizontal .thumb-content {
  text-align: center;
}
.nx-cross-selling-horizontal .thumb-content .vat {
  font-size: 10px;
  white-space: nowrap;
}
.nx-cross-selling-horizontal .widget-item-list-inline-caption p {
  margin-bottom: 15px;
}

.nx-cross-selling-vertical .widget-inner > .row .col-12 {
  width: 100%;
  display: block;
  max-width: none;
  flex: auto;
}
.nx-cross-selling-vertical .widget-caption p {
  font-size: 24.5px;
  margin-top: 18px;
  margin-bottom: 12px;
}
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb {
  margin-bottom: 10px;
}
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb:hover {
  position: relative;
  max-width: 100%;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.12);
}
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb .graduated-prices,
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb .technical-data-list {
  display: none !important;
}
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb .thumb-inner {
  display: flex;
  padding: 9px 2px 0 2px;
}
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb .thumb-inner .special-tags {
  display: none;
}
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb .thumb-inner .thumb-background {
  border: 1px solid #ddd;
}
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb .thumb-inner .thumb-image {
  width: 25%;
  padding: 0 3px;
}
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb .thumb-inner .thumb-content {
  width: 75%;
  padding: 0 3px;
}
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb .thumb-inner .thumb-content .thumb-meta {
  margin-top: 0;
}
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb .thumb-inner .thumb-content .thumb-title {
  font-size: 12px;
  font-weight: normal;
  color: #495057;
}
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb .thumb-inner .thumb-content .price {
  color: #495057;
  font-size: 15px;
  margin-top: 3px;
  line-height: 18px;
}
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb .thumb-inner .thumb-content .small {
  font-size: 85%;
  color: #495057;
}
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb .thumb-inner .thumb-content .vat {
  line-height: 1;
  font-size: 10px;
  white-space: nowrap;
}
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb .thumb-inner .thumb-content .vat div {
  color: #495057;
}
.nx-cross-selling-vertical .nx-item-list-item.cmp-product-thumb .thumb-inner .thumb-content .vat div .nxtooltip a {
  color: #fa7d19 !important;
}

/* Category Items */
.nx-cs-item-list-item.cmp-product-thumb {
  border: 1px solid gray;
  border-radius: 0px;
  padding: 0;
}
.nx-cs-item-list-item.cmp-product-thumb .thumb-inner .add-to-basket-lg-container {
  display: none;
}
.nx-cs-item-list-item.cmp-product-thumb .technical-data-list {
  display: none;
}
.nx-cs-item-list-item.cmp-product-thumb .graduated-prices {
  display: none;
}
.nx-cs-item-list-item.cmp-product-thumb:hover {
  position: absolute;
  z-index: 900;
  max-width: 98%;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
}
.nx-cs-item-list-item.cmp-product-thumb:hover .technical-data-list {
  display: block;
}
.nx-cs-item-list-item.cmp-product-thumb:hover .graduated-prices {
  display: block;
}

.nx-cs-atb-cont {
  display: block;
  font-size: 12px;
  min-width: 90px;
  padding: 6px 4px;
}

.prices.cross_price.nx-cs-crossprice {
  min-width: 60px;
  min-height: 30px;
}

/* -----------------------------FOOTER------------------------------ */
.footer {
  background: #ebedf2;
  max-width: none;
  padding-top: 20px;
  border-top: 1px solid #e3e3e3;
  margin-top: 25px;
}

.image-list {
  display: flex;
  list-style: none;
  margin: 5px auto;
  text-align: center;
  justify-content: center;
}

@media (max-width: 899px) {
  .image-list {
    flex-flow: wrap;
  }
}
.footer .image-list img {
  margin: 2px 25px;
}

.page-content.checkout .col-12 .h2 {
  text-align: center;
}

.footer ul {
  line-height: 1.8;
}

.footer .btn-secondary {
  background-color: #fa7d19;
  border-color: #fa7d19;
}

@media (max-width: 899px) {
  .footer ul {
    padding-inline-start: 0;
  }
}
.footer ul li a {
  font-size: 12px;
}

.footer .h4,
.footer h4,
.widget-newsletter .widget-inner .row .col-xs-12 p {
  margin-top: 1em !important;
  text-transform: uppercase;
  color: #555;
  text-rendering: optimizelegibility;
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 12px;
  padding-bottom: 12px;
  line-height: 24px;
  font-weight: bold;
}

.widget-newsletter .widget-inner .row .col-xs-12 p:after,
.footer .h4:after,
.footer h4:after {
  content: "";
  display: block;
  border-bottom: 1px solid #dddddd;
  height: 0px;
  position: relative;
  left: -15px;
  width: 130%;
}

@media (min-width: 1200px) {
  .footer .row {
    max-width: 1200px;
    margin: 0 auto;
  }
}
.footer-list {
  list-style: none;
  display: flex;
  justify-content: center;
  background-color: white;
  margin-left: calc(1200px - 100vw);
  margin-right: calc(1200px - 100vw);
  margin-bottom: 0;
}

@media (max-width: 899px) {
  .footer-list {
    flex-flow: wrap;
  }
}
#newsletter-input-form_16 {
  padding-bottom: 12px;
}

.footer-list li {
  margin: 0px 4px;
  color: #999999;
  font-size: 12px;
}

.footer-list li a {
  color: #999999;
}

.footer-logo {
  background: white;
  margin-left: calc(1200px - 100vw);
  margin-right: calc(1200px - 100vw);
  margin-bottom: -2rem;
  padding-top: 24px;
}

.claim {
  text-align: center;
  font-size: 12px;
  color: #495057;
  background-color: white;
  margin-left: calc(1200px - 100vw);
  margin-right: calc(1200px - 100vw);
  margin-bottom: -2rem;
  padding-bottom: 12px;
}

.claim span {
  background-color: #ddd;
  line-height: 24px;
}

@media (max-width: 1199px) {
  .footer-list,
.claim,
.footer-logo {
    margin-left: -30px;
    margin-right: -30px;
  }
}
@media (max-width: 899px) {
  .footer-list,
.claim,
.footer-logo {
    margin-left: -15px;
    margin-right: -15px;
  }

  .claim span {
    background-color: white;
    color: #999999;
  }

  .footer-logo {
    padding-bottom: 12px;
  }
}
.upload-list .v--modal-box button {
  font-size: 12px;
}
.upload-list .v--modal-box .header-holder {
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  min-height: 16.42857143px;
  background: #fafafa;
}
.upload-list .v--modal-box .header-holder h3 {
  font-size: 17.5px;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  line-height: normal;
  margin-bottom: 0;
}
.upload-list .v--modal-box .header-holder p {
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  -khtml-opacity: 0.2;
  -moz-opacity: 0.2;
  opacity: 0.2;
  margin-bottom: 0;
}
.upload-list .v--modal-box .modal-body {
  padding: 15px;
}
.upload-list .v--modal-box .modal-body p, .upload-list .v--modal-box .modal-body label, .upload-list .v--modal-box .modal-body strong {
  font-size: 13px;
}
.upload-list .v--modal-box .modal-body .text-info, .upload-list .v--modal-box .modal-body .text-success, .upload-list .v--modal-box .modal-body .text-danger {
  text-align: center;
}
.upload-list .v--modal-box .modal-body .flex-col {
  flex-direction: column;
}
.upload-list .v--modal-box .modal-body .col-sm-1 {
  font-weight: bold;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  -khtml-opacity: 0.2;
  -moz-opacity: 0.2;
  opacity: 0.2;
}
.upload-list .v--modal-box .modal-body .custom-file::before {
  background-color: #fa7d19;
  color: white;
}
.upload-list .v--modal-box .modal-body .button-holder {
  width: 100%;
}
.upload-list .v--modal-box .modal-body strong {
  width: 100%;
}
.upload-list .v--modal-box .bottom-buttons {
  margin-top: 15px;
  padding: 14px 15px 15px;
  text-align: right;
  border-top: 1px solid #e0e0e0;
}
.upload-list .v--modal-box .loader {
  position: relative;
  display: inline-block;
  margin-left: 50%;
  transform: translate(-50%) !important;
  width: 50px;
  height: 50px;
  border: 5px solid white;
  border-radius: 50%;
  animation: spin 0.5s infinite linear;
}
.upload-list .v--modal-box .loader::before,
.upload-list .v--modal-box .loader::after {
  left: -5px;
  top: -5px;
  display: none;
  position: absolute;
  content: "";
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
}
.upload-list .v--modal-box .spinner {
  border-color: transparent;
  border-top-color: rgba(250, 125, 25, 0.63);
  animation-duration: 3s;
}
.upload-list .v--modal-box .spinner::after {
  display: block;
  border-color: transparent;
  border-top-color: #fa7d19;
  animation: spin 1.5s infinite ease-in-out;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}