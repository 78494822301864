#startseite-carousel {
  .owl-stage {
    transition: all 2000ms ease 0s !important;
  }
}

.item {
  height: 480px;
}

.owl-theme .owl-nav {
  margin-top: 0px !important;
  text-align: unset !important;
}

.owl-theme .owl-nav [class*='owl-'] {
  color: #495057;
  opacity: .5;
  font-size: 20px;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  width: 20px;
  height: 20px;
  background-color: white;

  &:hover {
    background: none !important;
  }
}

.owl-carousel .owl-nav button.owl-next span,
.owl-carousel .owl-nav button.owl-prev span {
  font-size: 2em;
  font-weight: bold;
  position: absolute;
  top: 0;
  margin-top: -15px;
  left: 25%;

  &:hover {
    color: #495057;
  }
}

.owlcar-bg-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  object-fit: cover !important;
}

.owlcar-info {
  width: 50%;
  float: right;
  margin-top: 103px;
  padding-left: 15px;
  padding-right: 15px;
}

.owlcar-textholder {
  margin: 10px 12px 12px;
  transform: translate3d(40%, 0px, 0px);
  opacity: 0;
}

.owl-item.active .owlcar-info .owlcar-textholder {
  transition: opacity 0s, transform 2000ms ease 0s !important;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}

.owl-carousel h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin-bottom: 12px;
  line-height: 1.2;
  font-size: 36px;
  color: #555;
}

.owl-carousel strong {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 121%;
}

.owlcar-desc {
  margin-top: 17px;
}

.owlcar-img-thumbs {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  transform: translate3d(20%, 0px, 0px);
  opacity: 0;
}

.owl-item.active .owlcar-info .owlcar-img-thumbs {
  transition: opacity 0s, transform 2000ms ease 0s !important;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .item .owlcar-img-thumbs>img {
  width: auto;
  border: 1px solid #ddd;
  margin-left: 12px;
}

.owlcar-img-thumbs.trusted {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 12px;
}

.owl-carousel .item .owlcar-img-thumbs.trusted>img {
  border: none;
}

.owlcar-trusted-desc {
  font-size: 11px;
  line-height: 12px;
  padding: 15px;

}

.owlcar-trusted-rtg {
  width: 50%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 400px;
  transform: translate3d(40%, 0px, 0px);
}

.owl-item.active .owlcar-trusted-rtg {
  transition: all 2000ms ease 0s !important;
  transform: translate3d(0px, 0px, 0px);
}

.trusted-stars {
  font-size: 55px;
  line-height: 1;
  color: #edd27f
}

.trusted-rating-ratio {
  font-weight: 700;
  line-height: 1.73;
  font-size: 26px;
  font-family: "Open Sans Condensed", sans-serif;
}

.trusted-rating {
  font-weight: 700;
  font-size: 45px;
  line-height: 1;
  font-family: "Open Sans Condensed", sans-serif;
  margin-bottom: 50px;
}

@media (min-width: 900px) and (max-width: 1199px) {

  .item,
  .owlcar-trusted-rtg {
    height: 360px;
  }

  .owlcar-desc {
    font-size: 13px;
    margin-top: 1em;
  }

  .owl-carousel h1 {
    font-size: 24px;
  }

  .owl-carousel .item .owlcar-img-thumbs>img {
    height: 80px;
  }

  .owlcar-info {
    margin-top: 35px;
  }

  .trusted-rating {
    margin-bottom: 150px;
    font-size: 173%;
  }

  .trusted-stars {
    font-size: 211%;
  }
}

@media (min-width: 600px) and (max-width: 899px) {

  .item,
  .owlcar-trusted-rtg {
    height: 304px;
  }

  .owl-carousel h1 {
    font-size: 24px;
  }

  .owl-carousel strong {
    font-size: 12px;
  }

  .owlcar-info {
    margin-top: 0;
  }

  .owlcar-desc {
    font-size: 12px;
    margin-top: 1em;
  }

  .owlcar-img-thumbs,
  .owlcar-img-thumbs.trusted {
    display: none;
  }

  .trusted-stars {
    font-size: 211%;
  }

  .trusted-rating {
    font-size: 173%;
    margin-bottom: 130px;
  }
}

@media (max-width: 599px) {

  .item,
  .owlcar-trusted-rtg {
    height: 160px;
  }

  .owlcar-info {
    margin-top: 0;
  }

  .owl-carousel h1 {
    font-size: 13px;
    margin-top: 50px;
    padding-right: 12px;
  }

  .owl-carousel strong {
    display: none;
  }

  .owlcar-desc {
    display: none;
  }

  .owlcar-img-thumbs,
  .owlcar-img-thumbs.trusted {
    display: none;
  }

  .trusted-stars {
    font-size: 211%;
  }

  .trusted-rating {
    font-size: 173%;
  }

  .trusted-rating-ratio {
    display: none;
  }
}
