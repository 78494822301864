/* Category Items */
.nx-item-list-item.cmp-product-thumb {
  border: 1px solid gray;
  border-radius: 0px;
  padding: 0;

  .thumb-inner {
    .add-to-basket-lg-container {
      display: none;
    }
  }

  .technical-data-list {
    display: none;
  }

  .graduated-prices {
    display: none;
  }

  &:hover {
    position: absolute;
    z-index: 900;
    max-width: 90%;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .25);
  }

  &:hover .technical-data-list {
    display: block;
  }

  &:hover .graduated-prices {
    display: block;
  }
}


