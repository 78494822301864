#nx-custom-sidebar,
.nx-search-result-container {
    /* Sidebar Category-List - Level 1 */
    .sidebar-categories>ul>li {
        display: none;
    }
    .sidebar-categories>ul>li.active {
        display: block;
    }
    // Level 1 category title
    .sidebar-categories>ul>li.active>a {
        background: white;
        font-weight: bold;
        font-size: 12px;
        font-weight: 500;
        color: #495057;
        padding: 0.3rem 0 0.1rem 0rem;
    }
    /* Sidebar Category-List - Level 2 */
    .sidebar-categories>ul>li.active>ul {
        display: block;
    }
    .sidebar-categories>ul>li.active>ul {
        display: block;
    }
    /* Sidebar Category-List - Level 3 */
    .sidebar-categories>ul>li.active>ul>li {
        display: block;
    }
    .sidebar-categories>ul>li.active>ul>li.active>a {
        border: 1px solid #fa7d19;
        color: #495057;
    }
    .sidebar-categories>ul>li.active>ul>li>a {
        background: white;
        padding: 0.015rem 0.4rem;
        margin: 0.03rem 0;
        border: 1px solid transparent;
        font-size: 12px;
        color: #495057;
        &:hover {
            border: 1px solid #fa7d19;
        }
    }
    p.ww-category-title {
        color: #555;
        font-size: 17.5px;
        line-height: 36px;
        font-weight: 300;
        margin: 0 0 8px 0;
    }
}