/* -----------------------------FOOTER------------------------------ */

.footer {
  background: #ebedf2;
  max-width: none;
  padding-top: 20px;
  border-top: 1px solid #e3e3e3;
  margin-top: 25px;
}

.image-list {
  display: flex;
  list-style: none;
  margin: 5px auto;
  text-align: center;
  justify-content: center;
}

@media (max-width: 899px) {
  .image-list {
    flex-flow: wrap;
  }
}


.footer .image-list img {
  margin: 2px 25px;
}

.page-content.checkout .col-12 .h2 {
  text-align: center;
}


.footer ul {
  line-height: 1.8
}

.footer .btn-secondary {
  background-color: #fa7d19;
  border-color: #fa7d19;
}

@media (max-width: 899px) {
  .footer ul {
    padding-inline-start: 0;
  }
}

.footer ul li a {
  font-size: 12px;
}

.footer .h4,
.footer h4,
.widget-newsletter .widget-inner .row .col-xs-12 p {
  margin-top: 1em !important;
  text-transform: uppercase;
  color: #555;
  text-rendering: optimizelegibility;
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 12px;
  padding-bottom: 12px;
  line-height: 24px;
  font-weight: bold;
}

.widget-newsletter .widget-inner .row .col-xs-12 p:after,
.footer .h4:after,
.footer h4:after {
  content: "";
  display: block;
  border-bottom: 1px solid #dddddd;
  height: 0px;
  position: relative;
  left: -15px;
  width: 130%;
}

@media (min-width: 1200px) {
  .footer .row {
    max-width: 1200px;
    margin: 0 auto;
  }
}

.footer-list {
  list-style: none;
  display: flex;
  justify-content: center;
  background-color: white;
  margin-left: calc(1200px - 100vw);
  margin-right: calc(1200px - 100vw);
  margin-bottom: 0;
}

@media (max-width: 899px) {
  .footer-list {
    flex-flow: wrap;
  }
}

#newsletter-input-form_16 {
  padding-bottom: 12px;
}

.footer-list li {
  margin: 0px 4px;
  color: #999999;
  font-size: 12px;
}

.footer-list li a {
  color: #999999;
}

.footer-logo {
  background: white;
  margin-left: calc(1200px - 100vw);
  margin-right: calc(1200px - 100vw);
  margin-bottom: -2rem;
  padding-top: 24px;
}

.claim {
  text-align: center;
  font-size: 12px;
  color: #495057;
  background-color: white;
  margin-left: calc(1200px - 100vw);
  margin-right: calc(1200px - 100vw);
  margin-bottom: -2rem;
  padding-bottom: 12px;
}


.claim span {
  background-color: #ddd;
  line-height: 24px;
}

@media (max-width: 1199px) {

  .footer-list,
  .claim,
  .footer-logo {
    margin-left: -30px;
    margin-right: -30px;
  }
}

@media (max-width: 899px) {

  .footer-list,
  .claim,
  .footer-logo {
    margin-left: -15px;
    margin-right: -15px;
  }

  .claim span {
    background-color: white;
    color: #999999;
  }

  .footer-logo {
    padding-bottom: 12px;
  }

}
