/* ---------------------------- CE-CSS3 ----------------------------- */

.netxpress-megamenu {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .25);
}


#page-header .netxpress-megamenu .nav-content-wrapper #levelOneWrapper li.ww-level-one .layout-two-dropdown {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .25) !important;
}

.div.item-base-price.small.font-p70 {
  display: block;
}

.owl-carousel .owl-single-item-control {
  font-size: 2rem;
}

.right-topbar-section li.dropdown ul.dropdown-menu li {
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.right-topbar-section li.dropdown ul.dropdown-menu {
  padding: 10px 10px;
}

.right-topbar-section li.dropdown ul.dropdown-menu li:hover {
  border: 1px solid var(--nx-brand-primary);
}

.cross_sell .cross-selling .addtobasket {
  font-size: 9px;
}

span.nxtooltiptext.large-tooltip {
  display: none;
}

.nxrossseling-wi span.nxtooltiptext {
  display: none
}

.cmp-method-list .method-list-item label,
.cmp-method-list .method-list-item .label {
  height: 100%;
}

.feedback-container .title {
  color: var(--nx-brand-grey);
}

.feedback-container {
  color: var(--nx-brand-grey);
}

sup {
  display: none;
}

.feedback-bar-fill.bg-primary.bg-appearance {
  background-color: var(--nx-brand-primary) !important;
}

.totalSum {
  font-size: 16px;
  font-weight: 900;
}

@media (min-width: 900px) {
  a.toggle-basket-preview {
    height: 45px !important;
  }

  p[data-v-86b5249e] {
    height: 80px !important
  }

  .layout-two-dropdown {
    width: 1170px !important;
    margin-left: 15px;
  }

  .layout-one-dropdown {
    width: 1170px !important;
    margin-left: 15px;
  }
}

.qty-box {
  padding: 15px 0px 0px 0px;
}

.qty-box .qty-input {
  max-height: 49px;
  padding: 0px;
}

.qty-btn {
  max-height: 19px;
  float: left;
}

.basket-item-container-right {
  min-width: 150px;
}

.basket-item-container-right .qty-box-container {
  float: left;
}

.price-box .font-p90 {
  padding-top: 0px;
}

#addressMultiSelect16:after {
  content: "Adresse auswählen";
  color: var(--nx-brand-primary);
  cursor: pointer;
}

div[class^="addressMultiSelect"]:after,
div[class*=" addressMultiSelect"]:after {
  color: red
}

[class^="addressMultiSelect"]:after [class$="addressMultiSelect"]:after {
  color: red;
}

@media (max-width: 899px) {
  .netxpress-megamenu .nav-content-wrapper #levelOneWrapper li.ww-level-one {
    border-left: 1px solid #ddd;
    padding: 12px 1px 9px;
    height: 45px;
    float: left;
    margin-left: -1px;
  }

  .technical-data-list>dt[data-v-c0ad1828] {
    width: 36%;
  }
}



.sidebar-categories {
  display: none;
}

p.ww-category-title.mb-0 {
  display: none;
}

.item_availability .txt p.green {
  font-size: 1rem;
}

.cross_box .prices {
  margin-left: 12px;
}

a#nx-register-button {
  border-color: var(--nx-brand-primary) !important;
  background: #fff !important;
  color: var(--nx-brand-primary) !important;
}

a#nx-register-button:hover {
  border-color: var(--nx-brand-primary) !important;
  background: var(--nx-brand-primary) !important;
  color: #fff !important;
}

.breadcrumb {
  background-color: #fff !important;
}

.btn-nx-secondary {
  border-color: var(--nx-brand-primary) !important;
  background: #fff !important;
  color: var(--nx-brand-primary) !important;
}

.btn-nx-secondary:hover {
  border-color: var(--nx-brand-primary) !important;
  background: var(--nx-brand-primary) !important;
  color: #fff !important;
}

a.brand-logo.brand-logo-mobile img {
  position: relative;
  left: 40px;
  height: 40px;
}

.sticky-header-overlap .widget-inner {
  z-index: unset !important;
}

.single .variation-select .input-unit {
  margin-bottom: 0;
}

.single .single-carousel,
.page-singleitem .single-carousel {
  background: #fff;
  max-height: 500px;
}

img.owl-lazy {
  max-height: 480px;
}

div#thumb-carousel {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 15px;
}

@media (max-width: 900px) {

  .right-topbar-section li.dropdown a,
  .right-topbar-section li.dropdown div.login-change,
  .right-topbar-section li.list-inline-item a,
  .right-topbar-section li.list-inline-item div.login-change {
    border: none !important;
  }

  .netxpress-megamenu .nav-content-wrapper .mobile-content .mobile-menu-open-btn {
    left: 19px !important;
  }

  .right-topbar-section li.isPhone.dropdown {
    position: relative;
  }

  .add-to-basket-container button {
    padding: 0px 0px !important;
  }

  .page-singleitem .title-outer h1 span,
  .single div.title-outer h1 {
    color: var(--nx-brand-grey);
    font-size: 1.5rem;
    line-height: 31px;
  }
}

.registration.container-max.mt-3 .col-sm-10.offset-sm-1.col-md-6.offset-md-3 {
  border: 1px solid var(--nx-brand-grey)
}

.cmp-address-list .dropdown-toggle::after {
  content: "Adresse auswählen";
  color: var(--nx-brand-primary);
  margin: 8px;
  padding: 5px;
  position: absolute;
  top: 60%;
  padding-left: 15px;
  padding-right: 15px;
  right: 5px;
  border: none;
}

.row.wwcrosselling-itemname {
  height: 95px;
}

.qty-box {
  padding: 1px 0px 0px 0px;
}

img.owl-thumb.border-appearance {
  height: inherit !important;
  border: 1px solid #dee2e6;
  padding: 5px;
}

.owl-stage-outer.owl-height {
  height: inherit !important;
}

#thumb-carousel picture.active img.owl-thumb.border-appearance {
  border-color: var(--nx-brand-primary) !important;
}

div#thumb-carousel {
  padding-top: 10px;
}

div#thumb-carousel .owl-item.active {
  width: inherit !important;
}
#thumb-carousel>div.owl-stage-outer.owl-height .owl-item {
  width: inherit !important;
}

.nx-item-list-item.cmp-product-thumb {
  border: 1px solid #dee2e6;
}

.toggle-basket-preview {
  background: var(--nx-brand-primary) !important;
  height: inherit !important;
}

.search-submit {
  background-color: var(--nx-brand-primary) !important;
}

div#levelOneWrapper {
  margin-right: 3px;
}

.layout-one-dropdown {
  box-shadow: 0 1px 1px 1px #00000040 !important;
  -webkit-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .15);
  -moz-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .15);
}

div.invoice-addresses-select>div>div.cmp.cmp-address-list.mb-4>div>div.dropdown-menu>ul>li {
  height: 100%;
}

div.shipping-addresses-select>div>div.cmp.cmp-address-list.mb-4>div>div.dropdown-menu.show>ul>li {
  height: 100%
}

p.nx-csitemname {
  height: 75px;
}

.basket-preview-wrapper>a {
  margin-top: 100px;
}

.basket-item-text {
  display: content;
}


/* div.item-base-price.small.font-p70 {display:none;} */

.basket-preview-content>a {
  display: content;
}


/* ADS */

#thumb-carousel>div.owl-stage-outer.owl-height>div {
  width: 100%;
  display: flex;
  align-items: center;
}

.prices.cross_price.nx-cs-crossprice {
  padding-top: -5px !important;
  margin-left: 25px !important;
}

@media (max-width: 899px) {
  .netxpress-megamenu .nav-content-wrapper #levelOneWrapper li.ww-level-one {
    border-left: none !important;
  }
}

.item {
  height: 100% !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 889px !important;
    margin: 1.75rem auto;
  }
}

.widget-provider-select.widget-primary input:checked+.provider-select-label {
  border: solid 1px var(--nx-brand-primary);
}

.nx-shipping-box .item_availability:hover {
  cursor: pointer;
}

#page-body>div>div>div>div>div:nth-child(1)>div.widget-inner.col-xl-5.widget-prop-xl-auto.col-lg-5.widget-prop-lg-auto.col-md-12.widget-prop-md-3-1.col-sm-12.widget-prop-sm-3-1.col-12.widget-prop-3-1>div>div.widget.widget-sticky.sticky-element.is-sticky>div>div:nth-child(11)>div>div>div>div>div>div>div:nth-child(3) {
  display: none;
}

.owl-single-item-nav .fa-chevron-right,
.owl-single-item-nav .fa-chevron-left {
  -webkit-text-stroke: 2px white;
}


/*
.single-carousel.owl-carousel.owl-theme.owl-single-item.mt-0.owl-loaded.owl-drag {
    height: 350px;
}
div.single-carousel.owl-carousel.owl-theme.owl-single-item.mt-0.owl-loaded.owl-drag > div.owl-stage-outer.owl-height > div > div.owl-item.active > div > a > img{ height:300px;}
div.single-carousel.owl-carousel.owl-theme.owl-single-item.mt-0.owl-loaded.owl-drag > div.owl-stage-outer.owl-height > div > div.owl-item.active > div > a { height:300px;}
*/


/* Search Result Page Filter */

.nx-search-result-container #nx-filter-title {
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  padding: .3rem 0;
  margin-top: 1.2rem;
  margin-bottom: 0;
  color: var(--nx-brand-grey);
}

.nx-search-result-container .widget-filter-base .filter-toggle,
.nx-search-result-container .btn-primary,
.nx-search-result-container .form-check-wrapper .filter-badge {
  display: none;
}

.nx-search-result-container .filter-wrapper>.filter-collapse {
  display: block;
  position: relative;
  z-index: 50;
}

.nx-search-result-container .filter-collapse .page-content {
  padding: 0 !important;
  border: none;
  margin-top: 20px;
  margin-bottom: 1rem;
  background: #fff;
}

.nx-search-result-container .filter-collapse .page-content .h3 {
  font-weight: 500;
  font-size: 1.15rem;
  line-height: 1.2;
  padding: .3rem 0;
  margin-top: 1.2rem;
  margin-bottom: 1rem !important;
  color: #495057;
}

.nx-search-result-container .form-check-wrapper .form-check {
  padding: 0 3px;
  margin-bottom: 0;
}

.nx-search-result-container .form-check-wrapper input {
  vertical-align: middle;
}

.nx-search-result-container .search-results-categories .category-title {
  color: var(--nx-brand-grey);
  font-size: 17.5px;
  line-height: 36px;
  font-weight: 300;
  background: none;
  float: none;
  padding: 0;
}

.nx-search-result-container .search-results-categories ul {
  margin: 0;
  padding: 0;
}

.nx-search-result-container .search-results-categories ul>li>a {
  padding: .3rem 0 .1rem !important;
}

.nx-search-result-container .search-results-categories .nav-item .expand-nav {
  padding: .3rem 0 .1rem !important;
}

iframe[title="Trustami"] {
  z-index: 1005 !important;
}