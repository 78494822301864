span.custom-add-to-basket-container {
  div * {
    min-width: 100%;
    min-height: 28px;
  }

  button * {
    min-width: 100%;
    min-height: 28px;
  }

  .d-inline {
    &.d-lg-none {
      div {
        display: none;
      }
    }
  }
}

.nx-cross-selling-horizontal,
.nx-cross-selling-vertical {
  .widget-caption {
    padding: 0;
    margin-bottom: 0 !important;

    h2 {
      padding: 0;
    }
  }

  .owl-carousel .owl-stage-outer {
    overflow-x: hidden;
  }

  .category-unit-price {
    display: none;
  }
}

.nx-cross-selling-horizontal {
  .thumb-title {
    font: bold 14px "Open sans condensed", sans-serif;
    color: #495057;
    text-align: center;
    max-height: 38px;
    margin: 8px 0;
    padding: 0 10px;
    overflow: hidden;
  }

  .thumb-title span {
    display: none;
  }

  .thumb-content .thumb-meta {
    margin-top: 0;
  }

  .prices {
    justify-content: center;
    line-height: 24px;
  }

  .prices .price-view-port {
    -webkit-box-flex: 0;
    flex: 0;
    margin-right: 5px;
  }

  .prices .crossprice {
    font-size: 13px;
    font-family: "Open sans condensed", sans-serif;
    color: red;
    white-space: nowrap;
  }

  .prices .price {
    color: #495057;
    font: bold 15px "Open sans condensed", sans-serif;
    white-space: nowrap;
  }

  .thumb-content {
    text-align: center;
  }

  .thumb-content .vat {
    font-size: 10px;
    white-space: nowrap;
  }

  .widget-item-list-inline-caption {
    p {
      margin-bottom: 15px;
    }
  }
}

.nx-cross-selling-vertical {
  .widget-inner>.row .col-12 {
    width: 100%;
    display: block;
    max-width: none;
    flex: auto;
  }

  .widget-caption {
    p {
      font-size: 24.5px;
      margin-top: 18px;
      margin-bottom: 12px;
    }
  }

  .nx-item-list-item.cmp-product-thumb {
    margin-bottom: 10px;

    &:hover {
      position: relative;
      max-width: 100%;
      box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.12);
    }

    .graduated-prices,
    .technical-data-list {
      display: none !important;
    }

    .thumb-inner {
      display: flex;
      padding: 9px 2px 0 2px;

      .special-tags {
        display: none;
      }

      .thumb-background {
        border: 1px solid #ddd;
      }

      .thumb-image {
        width: 25%;
        padding: 0 3px;
      }

      .thumb-content {
        width: 75%;
        padding: 0 3px;

        .thumb-meta {
          margin-top: 0;
        }

        .thumb-title {
          font-size: 12px;
          font-weight: normal;
          color: #495057;
        }

        .price {
          color: #495057;
          font-size: 15px;
          margin-top: 3px;
          line-height: 18px;
        }

        .small {
          font-size: 85%;
          color: #495057;
        }

        .vat {
          line-height: 1;
          font-size: 10px;
          white-space: nowrap;

          div {
            color: #495057;

            .nxtooltip {
              a {
                color: #fa7d19 !important;
              }
            }
          }
        }
      }
    }
  }
}

/* Category Items */
.nx-cs-item-list-item.cmp-product-thumb {
  border: 1px solid gray;
  border-radius: 0px;
  padding: 0;

  .thumb-inner {
    .add-to-basket-lg-container {
      display: none;
    }
  }

  .technical-data-list {
    display: none;
  }

  .graduated-prices {
    display: none;
  }

  &:hover {
    position: absolute;
    z-index: 900;
    max-width: 98%;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
  }

  &:hover .technical-data-list {
    display: block;
  }

  &:hover .graduated-prices {
    display: block;
  }
}

.nx-cs-atb-cont {
  display: block;
  font-size: 12px;
  min-width: 90px;
  padding: 6px 4px;
}

.prices.cross_price.nx-cs-crossprice {
  min-width: 60px;
  min-height: 30px;
}
