.nx-search-result-container {
   .widget-filter-base {
     padding-left: 0 !important;
   }

   div.h3 {
     margin-bottom: 0 !important;
   }

   .widget-toolbar .list-controls-inner {
     .d-sm-flex {
       padding: 0 !important;
     }

     .list-sort {
       padding: 0 !important;
     }
   }

  .search-results-categories {
    .nav-link {
      border: 1px solid white !important;

      &:hover {
        background: white !important;
        border: 1px solid #fa7d19 !important;
      }
    }
  }
}
