.upload-list {
  .v--modal-box {
    button {
      font-size: 12px;
    }

    .header-holder {
      padding: 15px;
      border-bottom: 1px solid #e0e0e0;
      min-height: 16.42857143px;
      background: #fafafa;

      h3 {
        font-size: 17.5px;
        font-weight: normal;
        font-family: "Open Sans", sans-serif;
        line-height: normal;
        margin-bottom: 0;
      }

      p {
        font-size: 21px;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #ffffff;
        -khtml-opacity: .2;
        -moz-opacity: .2;
        opacity: .2;
        margin-bottom: 0;
      }
    }

    .modal-body {
      padding: 15px;

      p, label, strong {
        font-size: 13px;
      }

      .text-info, .text-success, .text-danger {
        text-align: center;
      }

      .flex-col {
        flex-direction: column;
      }

      .col-sm-1 {
        font-weight: bold;
        color: #000;
        text-shadow: 0 1px 0 #ffffff;
        -khtml-opacity: .2;
        -moz-opacity: .2;
        opacity: .2;
      }

      .custom-file::before {
        background-color: #fa7d19;
        color: white;
      }

      .button-holder {
        width: 100%;
      }

      strong {
        width: 100%;
      }
    }

    .bottom-buttons {
      margin-top: 15px;
      padding: 14px 15px 15px;
      text-align: right;
      border-top: 1px solid #e0e0e0
    }


    // LOAD IN PROCESS SPINNER
    .loader {
      position: relative;
      display: inline-block;
      margin-left: 50%;
      transform: translate(-50%) !important;
      width: 50px;
      height: 50px;
      border: 5px solid white;
      border-radius: 50%;
      animation: spin 0.5s infinite linear;
    }

    .loader::before,
    .loader::after {
      left: -5px;
      top: -5px;
      display: none;
      position: absolute;
      content: '';
      width: inherit;
      height: inherit;
      border: inherit;
      border-radius: inherit;
    }

    .spinner {
      border-color: transparent;
      border-top-color: rgba(250, 125, 25, 0.63);
      animation-duration: 3s;
    }

    .spinner::after {
      display: block;
      border-color: transparent;
      border-top-color: #fa7d19;
      animation: spin 1.5s infinite ease-in-out;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
