form.login-container, .widget-address form {
  div {
    input {
      padding: 1.5rem 1rem 0.5rem 1rem !important;
    }

    label {
      font-size: 0.8em;
      color: #555;
      padding-top: 6px;
    }
  }

  button {
    i.fa-user {
      display: none !important;
    }
  }
}

